export const globalStyles = {
  section: {
    padding: '50px 0',
    '& h3': {
      fontWeight: 800,
      fontSize: '2.5em',
      marginBottom: '30px'
    }
  }
}
