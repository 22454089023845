// HappyBox is a custom version of MUI's Paper component

import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

// MUI Imports
import { Paper } from '@material-ui/core';

const HappyBox = ({
  padding = '20px',
  marginBottom = '20px',
  ...props
}) => {

  const useStyles = makeStyles({
    root: {
      padding: padding === false ? 0 : padding,
      marginBottom: marginBottom,
      borderRadius: '10px',
      boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 14%)',
      ...props.styles
    },
  });

  const classes = useStyles(props);

  return(
    <Paper
      elevation={2}
      className={`${classes.root} ${props.className}`}
      id={props.id}
    >
      {props.children}
    </Paper>
  )
}

export default HappyBox;
