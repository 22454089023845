import React from 'react';
import { connect } from "react-redux";

// My Components
import Dashboard from "../main/Dashboard";
import LandingPage from "../landingPage/LandingPage";
// import Welcome from "../auth/Welcome";

const Landing = (props) => {

  // Both pages are at the same root url ('/')
  // if user is logged in -> Dashboard
  // if not logged in -> Welcome Page
  if (props.auth.isAuthenticated) {
    return <Dashboard />
  } else {
    return <LandingPage />
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(Landing);
