import React, { useState } from "react";
import "../../styles/getnumberpopup.css";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { verifyNumber } from "../../actions";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const useStyles = makeStyles({
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
  },
  hugeButton: {
    fontSize: "1.1em",
  },
});

const GetNumberPopup = (props) => {
  const { setOpenPopup, setOpenOtpPopup, userId } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // const numberHandler = (event) => {
  //   setPhoneNumber(event.target.value.trim());
  // };
  function isNumericStringValid(numberString) {
    // Check if the string contains only numeric digits and
    // (+) sign at start
    console.log(numberString);
    const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (
      // /^\+\d+$/.test(numberString) &&
      phoneRegex.test(numberString) &&
      numberString.length === 12 &&
      numberString !== ""
    ) {
      return true;
    }
    setErrorMessage("Invalid number");
    return false;
  }

  const phoneNumberHandler = (event) => {
    let numberString = event.target.value;
    console.log(numberString);
    if (/^\+\d+$/.test(numberString) && numberString.length === 12) {
      return;
    }
    setPhoneNumber(numberString);
  };

  const numberSubmitHandler = () => {
    setErrorMessage("");
    if (isNumericStringValid(phoneNumber)) {
      setLoading(true);
      // remove (+) sign from 1st if it exist
      verifyNumber(phoneNumber.replace(/^\+/, ""), userId)
        .then((res) => {
          console.log(res.message);

          if (res.message === "otp sent successfully") {
            setOpenPopup(false);
            setOpenOtpPopup(true);
          } else if (res.message === "Request failed with status code 401") {
            setErrorMessage("phone number already registered");
          } else if (res.message === "Request failed with status code 402") {
            setErrorMessage(
              "something went wrong or phone number is incorrect"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className="getNumber-container">
      <div
        className="getNumber-bg-close"
        onClick={() => setOpenPopup(false)}
      ></div>
      <div className="getNumber-data">
        <div className="getNumber-head">Enter Phone Number</div>
        <div style={{ paddingTop: "6px" }}>
          <p>
            By entering your <b>phone number</b> with <b>country code</b> and
            submitting this form, you consent to receive a one-time password
            (OTP) via SMS from Wanderlust for account verification purposes.
            Message and data rates may apply. You can opt-out at any time by
            replying 'STOP' to our messages (where available).
          </p>
          {/* <p>
            Format will be like this: [<b>country code</b>][<b>phone number</b>]
          </p>
          <p>12345678901</p> */}
        </div>
        {/* `value` will be the parsed phone number in E.164 format.
   Example: "+12133734253". */}
        <PhoneInput
          style={{ border: "0px" }}
          placeholder="Enter phone number"
          international
          defaultCountry="US"
          countries={["US"]}
          // countries={["US",'PK']}
          withCountryCallingCode
          countryCallingCodeEditable={false}
          value={phoneNumber}
          limitMaxLength
          // initialValueFormat='national'
          // onChange={(e)=>phoneNumberHandler(e)}
          onChange={setPhoneNumber}
        />

        {/* <input
          type="number"
          placeholder="phone number"
          className="getNumber-input hidden-number-input"
          value={phoneNumber}
          onChange={numberHandler}
        /> */}
        {errorMessage && (
          <div style={{ color: "red", fontSize: "14px", paddingTop: "8px" }}>
            {errorMessage}
          </div>
        )}
        <div className={classes.buttonBox}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
            className={classes.hugeButton}
            onClick={numberSubmitHandler}
          >
            {loading ? <CircularProgress /> : "confirm"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GetNumberPopup;
