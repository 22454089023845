import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// My Components
import FullPageBkg from "../layout/FullPageBkg";

// MUI Imports
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography } from '@material-ui/core';

// Actions
import { resetPassword } from "../../actions/index";

const useStyles = makeStyles({
  root: {},
  form: {
    '& .MuiTextField-root': {
      marginTop: '10px',
    }
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px'
  },
});

const ResetPassword = (props) => {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const { isAuthenticated } = props.auth
  const errors = props.errors

  const token = props.match.params.token

  // If logged in and user navigates to Login page, should redirect them to dashboard
  useEffect(() => {
    if (isAuthenticated) props.history.push("/");
  }, [isAuthenticated]);

  const handleSubmit = e => {
    e.preventDefault();

    const userData = {
      password,
      password2,
      token
    };

    props.resetPassword(userData);
  };

  const classes = useStyles();

  return(
    <FullPageBkg>
      <Typography variant="h2">
        Reset Password
      </Typography>

      <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>

        <TextField id="password" label="New Password" fullWidth
          onChange={e => setPassword(e.target.value)}
          value={password}
          error={errors.password || errors.passwordincorrect ? true : false}
          helperText={errors.password || errors.passwordincorrect}
          type="password"
        />
        <TextField id="password2" label="Confirm New Password" fullWidth
          onChange={e => setPassword2(e.target.value)}
          value={password2}
          error={errors.password2 ? true : false}
          helperText={errors.password2}
          type="password"
        />
        <div className={classes.buttonBox}>
          <Button type="submit" variant="contained" color="primary">
            Reset Password
          </Button>
        </div>
      </form>
    </FullPageBkg>
  )
}

const mapDispatchToProps = {
  resetPassword
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
