import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Mixpanel } from "../../utils/mixpanel";
import { Link } from "react-router-dom";

// MUI Imports
import {
  Typography, Button
} from '@material-ui/core';

// Images
import logo from "../../images/logo_white_200.png";
import bkgImage_1 from "../../images/paths/path_1.jpg";
import bkgImage_2 from "../../images/paths/path_2.jpg";
import bkgImage_3 from "../../images/paths/path_3.jpg";
import bkgImage_4 from "../../images/paths/path_4.jpg";
import bkgImage_5 from "../../images/paths/path_5.jpg";
import bkgImage_6 from "../../images/paths/path_6.jpg";
import bkgImage_7 from "../../images/paths/path_7.jpg";
import bkgImage_8 from "../../images/paths/path_8.jpg";
import bkgImage_9 from "../../images/paths/path_9.jpg";
import bkgImage_10 from "../../images/paths/path_10.jpg";

// Constants
const timePerImage = 7000 // wait time (ms) between images
const transitionTime = '3s' // transition time for fading between images

// Styles
const useStyles = makeStyles({
  hero: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '500px',
    maxHeight: '600px',
    minHeight: '500px',
    width: '100%',
    backgroundColor: '#817d77',
    background: 'linear-gradient(160deg, #3f4244 0%, #7d7773 30%, #9a948e 100%)',
    position: 'relative'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3
  },
  bkgImg: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover', // cover
    transition: `opacity ${transitionTime} ease`,
  },
  cover: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
  logo: {
    position: 'absolute',
    zIndex: 3,
    top: 0,
    left: 0,
    padding: '20px',
    '& img': {
      height: '65px'
    }
  },
  logIn: {
    position: 'absolute',
    zIndex: 3,
    top: 0,
    right: 0,
    padding: '10px',
    '& span': {
      color: '#fff'
    }
  },
  valueProp: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    zIndex: 1,
    '& h1, h4, h5': {
      color: '#fff',
      textShadow: '0px 0px 8px rgba(#000000, 0.5)'
    },
    '& h1': {
      fontSize: '4.6em',
      fontWeight: '800',
      letterSpacing: '-0.05em'
    },
    '& h4': {
      fontSize: '2.2em',
    },
    '& h5': {
      fontSize: '1.4em',
      marginBottom: '20px'
    },
    '& a': {
      marginTop: '10px'
    }
  },
  ctaButton: {
    fontSize: '1.2em'
  }
});

const Hero = (props) => {
  const classes = useStyles();

  const [ bkgImage, setBkgImage ] = useState(0)

  // Array of images to cycle in hero
  const images = [
    bkgImage_1, bkgImage_2, bkgImage_3, bkgImage_4, bkgImage_5, bkgImage_6,
    bkgImage_7, bkgImage_8, bkgImage_9, bkgImage_10
  ]

  useEffect(() => {
    // Init timer for image cycling
    const timer = setTimeout(changeBkgImage, timePerImage)
    return () => {
      clearTimeout(timer);
    };
  }, [bkgImage]);

  const changeBkgImage = () => {
    if (bkgImage < images.length - 1) {
      setBkgImage(bkgImage + 1)
    } else {
      setBkgImage(0)
    }
  }

  const handleClickGetStarted = () => {
    Mixpanel.track("Clicked CTA Button", {
      text: 'Get Started'
    });
    // props.history.push("/register")
    props.history.push("/plans")
  }

  return(
    <div className={classes.hero}>
      <div className={`${classes.cover} ${classes.content}`}>
        <div className={classes.valueProp}>
          <Typography variant='h1' align='center'>
            Find Your Path
          </Typography>
          <Typography variant='h4' align='center'>
            Data-Driven Career Assessments
          </Typography>
          <Typography variant='h5' align='center' gutterBottom >
            backed by psychology, research, and psychometrics
          </Typography>
          <Button variant="contained" className={classes.ctaButton}
            onClick={() => handleClickGetStarted()}
            color="primary" size='large'
          >
            Get Started
          </Button>
        </div>
      </div>

      {/* Logo */}
      <div className={classes.logo}>
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>

      {/* Log In */}
      <div className={classes.logIn}>
        <Button component={Link} to="/login" size='small'>
          Log In
        </Button>
      </div>

      {/* ALL BKG IMAGES */}
      <div className={classes.cover}>
        {images.map((url, index) => (
            <div
              key={index} className={`${classes.cover} ${classes.bkgImg}`}
              style={{
                backgroundImage: `url(${url})`,
                opacity: bkgImage === index ? 1 : 0
              }} />
          ))
        }
      </div>

      {/* Darken Overlay */}
      <div className={`${classes.cover} ${classes.overlay}`} />
    </div>
  )
}

export default Hero;
