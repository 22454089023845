import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// MUI Imports
import { Typography, Paper, CircularProgress } from '@material-ui/core';

// My Imports
import Centered from "../layout/Centered";
import HappyBox from "../layout/HappyBox";

// GQL Query Imports
import { GET_DEPRESSION_RESULTS } from './constants/queries';

// My Imports
import DepressionVis from '../widgits/DepressionVis';
// import { interpretation } from '../../constants/results/depression';

const useStyles = makeStyles({
  root: {
    padding: '20px',
    marginTop: '20px',
    marginBottom: '50px'
  },
  title: {
    marginBottom: '20px'
  },
  basicsContainer: {
    marginBottom: '20px',
    '& p': {
      whiteSpace: 'pre-wrap',
      marginBottom: '20px'
    }
  }
});

const DepressionResults = (props) => {
  const classes = useStyles();
  const { results } = props

  // Load Contentful Data
  const { loading, error, data } = useQuery(GET_DEPRESSION_RESULTS);

  if (loading) {
   return(
     <Centered>
       <CircularProgress />
     </Centered>
   )
  } else {
    const assessment = data.depressionResults.assessment
    const analysis = data.depressionResults.details.items[0][results.depression.toLowerCase()]
    const interpretation = data.depressionResults.interpretation.json
    const disclaimer = data.depressionResults.disclaimer.json

    return(
      <>
        <HappyBox>
          <Typography variant='h4' gutterBottom>
            Depression Screening | BDI-II
          </Typography>

          <Typography gutterBottom component='div'>
            { documentToReactComponents(assessment.longDescription.json) }
          </Typography>
        </HappyBox>

        <HappyBox>
          <Typography variant='h4' gutterBottom>
            Your Results
          </Typography>

          <DepressionVis value={results.score} />

          <div style={{marginBottom: '20px'}}>
            <Typography>
              <b>Score: </b>{results.score}
              <br/>
              <b>Depression Level: </b>{results.depression}
            </Typography>
          </div>

          <Typography gutterBottom>
            {/* {interpretation.results[results.depression.toLowerCase()]} */}
            { analysis }
          </Typography>

          <Typography gutterBottom component='div'>
            {documentToReactComponents(interpretation)}
          </Typography>
        </HappyBox>

        <HappyBox>
          <Typography gutterBottom component='div'>
            {documentToReactComponents(disclaimer)}
          </Typography>
        </HappyBox>
      </>
    )
  }
}

export default DepressionResults
