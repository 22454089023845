import axios from "axios";
import { batch } from "react-redux";

import { sendFlash, setUserConsent } from "../actions/index";

export const UPDATE_ANSWER = "UPDATE_ANSWER";
export const UPDATE_ALL_ANSWERS = "UPDATE_ALL_ANSWERS";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const INIT_CARD_SORT = "INIT_CARD_SORT";
export const REORDER_CARD = "REORDER_CARD";
export const MOVE_CARD = "MOVE_CARD";
export const SET_ANSWER_LOADING_STATUS = "SET_ANSWER_LOADING_STATUS";
export const SET_COMPLETED = "SET_COMPLETED";

// update redux state with new answer
export const updateAnswer = (
  userId,
  assessmentId,
  question,
  answer,
  questionType
) => {
  return {
    type: UPDATE_ANSWER,
    userId: userId,
    assessmentId: assessmentId,
    question: question,
    answer: answer,
    questionType: questionType,
  };
};

// update all answers for a paricular assessment
export const updateAllAnswers = (answerData) => {
  return {
    type: UPDATE_ALL_ANSWERS,
    answerData: answerData,
  };
};

// reorders card in current list
export const reorderCard = (assessmentId, listId, listData) => {
  return {
    type: REORDER_CARD,
    listId: listId,
    assessmentId: assessmentId,
    listData: listData,
  };
};

// moves card to new list
export const moveCard = (assessmentId, cardData, sourceId, destId) => {
  return {
    type: MOVE_CARD,
    assessmentId: assessmentId,
    cardData: cardData,
    sourceId: sourceId,
    destId: destId,
  };
};

export const initializeCardSort = (assessmentId, questions) => {
  return {
    type: INIT_CARD_SORT,
    assessmentId: assessmentId,
    questions: questions,
  };
};

// updates completed and quesitons answered for an assessment
export const updateProgress = (
  userId,
  assessmentId,
  questionsAnswered,
  markComplete = true
) => {
  return {
    type: UPDATE_PROGRESS,
    userId: userId,
    assessmentId: assessmentId,
    questionsAnswered: questionsAnswered,
    checkComplete: markComplete,
  };
};

// Marks assessment as completed
export const setCompleted = (assessmentId) => {
  return {
    type: SET_COMPLETED,
    assessmentId: assessmentId,
  };
};

export const setLoadingStatus = (assessmentId, status) => {
  return {
    type: SET_ANSWER_LOADING_STATUS,
    assessmentId: assessmentId,
    status: status,
  };
};

// save answers
// send all answers back to database
// update redux state to reflect changes
// markComplete=false will override marking an assessment as complete even if all questions have been answered
export const submitAnswers =
  (answerData, markComplete = true) =>
  (dispatch, getState) => {
    const { userId, assessmentId } = answerData;
    let answers = [];
    if (!answerData.answers) {
      // add answers from redux if they weren't passed in answerData object
      answers = getState().answers.byId[assessmentId].answers; // temp fix - should only get answers through getState once all components connect questions indivdually
      answerData.answers = answers;
    }
    axios.post("/api/answers", answerData).then(
      (response) => {
        let saved = response.data.saved;
        if (saved) {
          batch(() => {
            switch (assessmentId) {
              case "career-values":
                if (markComplete) {
                  dispatch(
                    updateProgress(
                      userId,
                      assessmentId,
                      answerData.totalQuestions - answers.start.cards.length
                    )
                  );
                } else {
                  dispatch(
                    updateProgress(
                      userId,
                      assessmentId,
                      answerData.totalQuestions - answers.start.cards.length,
                      false
                    )
                  );
                }
                dispatch(sendFlash("Answers Saved!", "success"));
                break;

              case "consent":
                dispatch(setUserConsent());
                dispatch(updateProgress(userId, assessmentId, 1));
                dispatch(sendFlash("Consent Complete!", "success"));
                break;

              case "interview":
                dispatch(
                  updateProgress(
                    userId,
                    assessmentId,
                    Object.keys(answers).length
                  )
                );
                if (markComplete) {
                  dispatch(setCompleted(assessmentId));
                }
                dispatch(sendFlash("Answers Saved!", "success"));
                break;

              case "myers-briggs":
                dispatch(
                  updateProgress(
                    userId,
                    assessmentId,
                    answerData.totalQuestions
                  )
                );
                dispatch(sendFlash("Answers Saved!", "success"));
                // this is where we should fetch the elevate data and sync it with db
                break;

              case "neo": {
                let answerCount = 0;
                answers.forEach((answer) => {
                  if (answer !== null) {
                    answerCount += 1;
                  }
                });
                dispatch(
                  updateProgress(
                    userId,
                    assessmentId,
                    answerCount,
                    markComplete
                  )
                );
                dispatch(sendFlash("Answers Saved!", "success"));
                break;
              }

              case "depression-screening": {
                // get answer count for array of answers (don't count null values)
                let answerCount = 0;
                answers.forEach((answer) => {
                  if (answer !== null) {
                    answerCount += 1;
                  }
                });
                dispatch(updateProgress(userId, assessmentId, answerCount));
                dispatch(sendFlash("Answers Saved!", "success"));
                break;
              }

              default:
                dispatch(
                  updateProgress(
                    userId,
                    assessmentId,
                    Object.keys(answers).length
                  )
                );
                dispatch(sendFlash("Answers Saved!", "success"));
                break;
            }
          });
        }
      },
      (error) => {
        dispatch(
          sendFlash(`Failed to save answers - error: ${error}`, "error")
        );
      }
    );
  };

// fetch answers for an assessment from backend
export const fetchAnswers = (answerData) => (dispatch, getState) => {
  const { userId, assessmentId } = answerData;
  const questions = getState().assessments.byId[assessmentId].questions;

  dispatch(setLoadingStatus(assessmentId, true));
  axios
    .get("/api/answers", {
      params: { userId: userId, assessmentId: assessmentId },
    })
    .then(
      (response) => {
        let answerData = response.data;
        if (answerData && answerData.answers) {
          batch(() => {
            dispatch(setLoadingStatus(assessmentId, "done"));
            dispatch(updateAllAnswers(answerData));
          });
        }
      },
      (error) => {
        if (assessmentId === "career-values") {
          batch(() => {
            dispatch(setLoadingStatus(assessmentId, "done"));
            dispatch(initializeCardSort(assessmentId, questions));
          });
        } else {
          dispatch(setLoadingStatus(assessmentId, "done"));
        }
      }
    )
    .catch((err) => console.log(err));
};

//get user access token for myer-briggs login
export const getToken = async (userId) => {
  const response = await axios
    .post(`/api/answers/login-myer?userId=${userId}`)
    .catch((err) => {
      console.log("error:", err);
      // return dispatch(updateErrors(err));
      return err;
    });
  if (response.data) {
    console.log("access token:", response.data);
    return response.data;
  }
};
