import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Box, Typography } from "@mui/material";
import moment from "moment";

import {
  // InlineWidget,
  CalendlyEventListener,
  openPopupWidget,
} from "react-calendly";

// MUI Imports
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";

// Icons
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import icon from "../../images/logo_100.png";

// Actions
import { requestSetUserSchedule, sendFlash } from "../../actions/index";

// Constants
import coaches from "./constants/coaches.json";
import { display } from "@mui/system";

const useStyles = makeStyles({
  root: {},
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgb(255, 255, 255,0)",
  },
  modalBox: {
    width: "400px",
    background: "#ffff",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    justifyContent: "space-between",
  },
  image: {
    borderBottom: "1px solid rgb(0,0,0,0.1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  note: {
    fontWeight: "500",
    paddingBottom: "20px",
  },
  coachesList: {
    padding: "20px",
  },
  coach: {
    color: "rgba(26,26,26,0.6)",
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "24px",
    cursor: "pointer",
    transition: "0.3s",
    padding: "0px 5px",
    margin: "5px 0px",
    "&:hover": {
      backgroundColor: "rgba(26,26,26,0.2)",
      transition: "0.3s",
    },
  },
});

const CalendlyAppointmentList = (props, coach) => {
  const { planId, user, allAssesmentsCompleted } = props;
  const appointments = props.user.appointments;

  const classes = useStyles();
  const [eventType, setEventType] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sessionType, setSessionType] = useState("");

  const handleOpenCalendlyPopup = (type, selectedCoach) => {
    setEventType(type);

    const calendlyLinks = {
      interview:
        selectedCoach === "Judd Kimber"
          ? "https://calendly.com/judd-23/interview-session"
          : selectedCoach === "Niki Yarnot"
          ? "https://calendly.com/niki-54/interview-session"
          : "https://calendly.com/jonathan-wanderlustcareers/interview-session",

      results:
        selectedCoach === "Judd Kimber"
          ? "https://calendly.com/judd-23/results-session"
          : selectedCoach === "Niki Yarnot"
          ? "https://calendly.com/niki-54/results-session"
          : "https://calendly.com/jonathan-wanderlustcareers/results-session",
    };

    // Works with Calendly Pro account only
    const pageSettings = {
      // backgroundColor: '#f06944',
      // hideLandingPageDetails: false,
      // primaryColor: '00a2ff',
      // textColor: '4d5055'
    };

    // these parameters will prefill the calendly popup with user data
    const prefill = {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      // firstName: user.firstName,
      // lastName: user.lastName,
      // customAnswers: {
      //   a1: user._id,
      //   a2: user.email,
      //   test: 'test'
      // }
    };

    // Okay these utm parameters are stupid - think theyre used for tracking ad campaigns or something
    // good news is they persist with calendly data with subsequent api calls
    // we need a dependable way to associate calendly events (that trigger webhooks) with a user in our db
    // by dumping the user id in one of these utm params, we can then look up the user by id later when responding to a webhook!
    const utm = {
      utmSource: user._id, // storing user id here!
      // utmCampaign: user._id,
      // utmCampaign: 'Spring Sale 2019',
      // utmMedium: "Ad",
      // utmContent: "Shoe and Shirts",
      // utmTerm: "Spring",
      // salesforceUuid: user._id,
    };

    openPopupWidget({
      url: calendlyLinks[type],
      prefill,
      pageSettings,
      utm,
    });
  };

  const handleCalendlyEvent = (e) => {
    const event = e.data.event;

    switch (event) {
      case "calendly.event_type_viewed":
        console.log("Calendly: popup opened");
        break;

      case "calendly.date_and_time_selected":
        // date and time selected - apt not confirmed yet
        console.log("Calendly: date and time selected");

        // scheduleFakeAppointment()

        break;

      // event scheduled
      case "calendly.event_scheduled": {
        const payload = e.data.payload;

        // these are api endpoints - need to authenticate to view data...
        const eventUrl = payload.event.uri; // "https://api.calendly.com/scheduled_events/CD35M6WCT2OAH53H"
        const inviteeUrl = payload.invitee.uri; // "https://api.calendly.com/scheduled_events/CD35M6WCT2OAH53H/invitees/EMFKYQ74KYRYQIAU"

        // cancel url: https://calendly.com/cancellations/EMFKYQ74KYRYQIAU
        // reschedule url: https://calendly.com/reschedulings/EMFKYQ74KYRYQIAU?month=2021-07

        const data = {
          type: eventType,
          eventUrl: eventUrl,
          inviteeUrl: inviteeUrl,
        };

        props.requestSetUserSchedule(data); // userActions.js

        console.log("Calendly: meeting scheduled!");
        props.sendFlash("Meeting Scheduled!", "success");
        break;
      }
      default:
        console.log("Calendly: other event triggered");
        break;
    }
  };

  const showDate = (type) => {
    if (appointments?.[type]?.scheduled) {
      if (appointments?.[type]?.startTime) {
        return moment(appointments[type].startTime).format(
          "MMMM Do, YYYY @ h:mm a"
        );
        // return props.user.appointments.[type].startTime
      } else return "Scheduled"; // Legacy support (for when there's no date)
    } else {
      if (type === "interview" || allAssesmentsCompleted) {
        return "Not Scheduled";
      } else {
        return "Please finish all assessments before scheduling";
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = (type) => {
    setSessionType(type);
    setShowModal(true);
  };

  const showCoaches = () => {
    return (
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalContainer}
      >
        <Box className={classes.modalBox}>
          <div className={classes.image}>
            <img src={icon} />
          </div>
          <div className={classes.coachesList}>
            <div className={classes.note}>
              Please select your Career Coach from the options below:
            </div>
            {coaches.list.map((coach, index) => {
              return (
                <div
                  key={index}
                  className={classes.coach}
                  onClick={async () => {
                    await handleOpenCalendlyPopup(sessionType, coach.name);
                    setShowModal(false);
                  }}
                >
                  {coach.name + " " + coach.education}
                </div>
              );
            })}
          </div>
        </Box>
      </Modal>
    );
  };

  if (planId === "essential" || planId === "premium") {
    const interviewScheduled = appointments?.interview?.scheduled || false;
    const resultsScheduled = appointments?.results?.scheduled || false;

    return (
      <List>
        {/* RESCHEDULE URL */}
        {/* https://calendly.com/reschedulings/AKHWHKO247GMLXQN (userId) */}

        {/* INTERVIEW */}
        {planId === "premium" && (
          <ListItem>
            <ListItemIcon>
              {interviewScheduled ? <EventAvailableIcon /> : <EventBusyIcon />}
            </ListItemIcon>

            <ListItemText
              primary="Interview Session"
              secondary={showDate("interview")}
            />
            {interviewScheduled ? (
              appointments.interview.userId && (
                <Button
                  variant="outlined"
                  size="small"
                  target="newTab"
                  href={`https://calendly.com/reschedulings/${appointments.interview.userId}`}
                >
                  Reschedule
                </Button>
              )
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  // handleOpenCalendlyPopup("interview")
                  handleOpen("interview");
                }}
              >
                Schedule
              </Button>
            )}
          </ListItem>
        )}

        {/* RESULTS */}
        <ListItem>
          <ListItemIcon>
            {resultsScheduled ? <EventAvailableIcon /> : <EventBusyIcon />}
          </ListItemIcon>

          <ListItemText
            primary="Results Session"
            secondary={showDate("results")}
          />
          {resultsScheduled
            ? appointments.results.userId && (
                <Button
                  variant="outlined"
                  size="small"
                  target="newTab"
                  href={`https://calendly.com/reschedulings/${appointments.results.userId}`}
                >
                  Reschedule
                </Button>
              )
            : allAssesmentsCompleted && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    // handleOpenCalendlyPopup("results");
                    handleOpen("results");
                  }}
                >
                  Schedule
                </Button>
              )}
        </ListItem>
        <CalendlyEventListener
          onEventTypeViewed={handleCalendlyEvent}
          onDateAndTimeSelected={handleCalendlyEvent}
          onEventScheduled={handleCalendlyEvent}
          // onProfilePageViewed={handleCalendlyEvent}
        />
        {showModal ? showCoaches() : null}
      </List>
    );
  } else return null;
};

const mapDispatchToProps = {
  requestSetUserSchedule,
  sendFlash,
};

export default connect(null, mapDispatchToProps)(CalendlyAppointmentList);
