import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DoneIcon from "@material-ui/icons/Done";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// MUI Imports
import {
  Container,
  Typography,
  Button,
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";

import { colors } from "../../constants/colors";

// My Imports
import Centered from "../layout/Centered";

// Actions
import {
  sendFlash,
  submitAnswers,
  updateAnswer,
  fetchAnswers,
  fetchUserData,
} from "../../actions/index";
import GetNumberPopup from "../widgits/GetNumberPopup";
import GetVerifyOtpPopup from "../widgits/GetVerifyOtpPopup";

const useStyles = makeStyles({
  root: {
    padding: "20px",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "50px",
  },
  hugeButton: {
    fontSize: "1.3em",
  },
  paper: {
    marginTop: "30px",
    padding: "40px",
  },
  button: {
    margin: "10px",
  },
  questionContainer: {
    marginTop: "20px",
    padding: "20px",
    borderRadius: "4px",
  },
  error: {
    backgroundColor: colors.red,
  },
});

const MyersBriggs = (props) => {
  const { user, assessment, gender, answersLoading } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openNumberPopup, setOpenNumberPopup] = useState(false);
  const [openOtpPopup, setOpenOtpPopup] = useState(false);

  const [error, setError] = useState(false);
  // const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (!props.answersLoading) {
      // on load fetch completed answers
      let answerData = {
        userId: props.user._id,
        assessmentId: props.assessment._id,
      };
      props.fetchAnswers(answerData);
    }
  }, []);

  const handleOpen = () => {
    if (gender !== "") {
      setOpen(true);
    } else {
      setError(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    let answerData = {
      userId: user._id,
      assessmentId: "myers-briggs",
      totalQuestions: assessment.totalQuestions,
      answers: [gender],
    };
    const answer = await props.submitAnswers(answerData);
    console.log(answer);
  };

  const handleRadioChange = (event) => {
    setError(false);
    const questionNum = event.target.name;
    const answer = event.target.value;
    props.updateAnswer(user._id, assessment._id, questionNum, answer);
  };

  const verifyNumberOrRedirect = () => {
    if (user.isNumVerified) {
      const newTab = window.open("", "_blank");
      newTab.location.href = `/myer-briggs/?userId=${user._id}`;
    } else {
      setOpenNumberPopup(true);
    }
  };
  return (
    <Container maxWidth="md" className={classes.root}>
      {answersLoading === true && (
        <Centered>
          <CircularProgress />
        </Centered>
      )}
      <Paper className={classes.paper}>
        <Typography variant="h2" gutterBottom>
          MBTI & <br /> Strong Interest Inventory
        </Typography>
        <Typography>
          These two tests are owned and administered by the Myers Briggs
          Company. Follow the link below to go take the tests. <br></br>
          <br></br>
          You'll need to create an account with them, but{" "}
          <b>
            please use the same email address you used to create your account
            here
          </b>{" "}
          so that our systems can sync your data. <br></br>
          <br></br>
          When you've completed BOTH tests, please return here and let us know
          by clicking “I'm Finished”!
        </Typography>

        <div
          className={`${classes.questionContainer} ${
            error ? classes.error : ""
          }`}
        >
          <FormControl>
            <FormLabel className={classes.questionText}>
              What is your Gender? (for the purposes of scoring these tests)
            </FormLabel>
            <RadioGroup
              row
              name="1"
              value={gender}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="m" control={<Radio />} label="Male" />
              <FormControlLabel value="f" control={<Radio />} label="Female" />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
        </div>

        {/* <div className={classes.buttonBox}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.hugeButton}
            target="_blank"
            href="https://elevate.cpp.com/Respondent/ReturningUser?tokenId=12223448-0046-4f07-ad8b-22cee3fe851d"
            startIcon={<ExitToAppIcon />}
          >
            Take me to the Myers Briggs Assessments!
          </Button>
            </div> */}
        <div className={classes.buttonBox}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.hugeButton}
            // target="_blank"
            // href={`/myer-briggs/?userId=${props.user._id}&number=${phoneNumber}`}
            onClick={verifyNumberOrRedirect}
            startIcon={<ExitToAppIcon />}
          >
            Go to Myers Briggs
          </Button>
        </div>

        {openNumberPopup && (
          <GetNumberPopup
            setOpenPopup={setOpenNumberPopup}
            setOpenOtpPopup={setOpenOtpPopup}
            // setPhoneNumber={setPhoneNumber}
            // phoneNumber={phoneNumber}
            userId={props.user._id}
          />
        )}
        {openOtpPopup && (
          <GetVerifyOtpPopup
            setOpenOtpPopup={setOpenOtpPopup}
            // phoneNumber={phoneNumber}
            userId={props.user._id}
          />
        )}

        <div className={classes.buttonBox}>
          <Button
            variant="contained"
            component={Link}
            to="/"
            className={classes.button}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleOpen}
            startIcon={<DoneIcon />}
          >
            I'm Finished
          </Button>
        </div>
      </Paper>

      {/* DIALOG */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Are You Sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please make sure you've completed BOTH assessments on the Myers
            Briggs website and submitted your answers before continuing.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            component={Link}
            to="/"
            color="primary"
            autoFocus
          >
            Yes, I'm totally finished
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const mapDispatchToProps = {
  sendFlash,
  submitAnswers,
  updateAnswer,
  fetchAnswers,
};

const mapStateToProps = (state) => {
  const answers = state.answers.byId["myers-briggs"];
  return {
    assessment: state.assessments.byId["myers-briggs"],
    gender: state.answers?.byId?.["myers-briggs"]?.answers?.[0] || "",
    user: state.user,
    answersLoading: answers ? answers.loading : false,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyersBriggs);
