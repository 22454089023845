import { withStyles } from "@material-ui/core/styles";

// MUI Imports
import { Tooltip } from '@material-ui/core';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    padding: '15px',
    fontSize: '1em',
    '& p': {
      margin: 0
    }
  },
}))(Tooltip);

export default CustomTooltip
