import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";

// My Components
import Centered from "./Centered";

// MUI Imports
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {},
  subtitle: {
    color: 'rgb(119, 119, 119)'
  }
});

const Profile = (props) => {
  const classes = useStyles();
  const { user } = props

  return(
    <Centered>
      <Typography variant="h4">
        {user.firstName} {user.lastName}
      </Typography>
      <p>Email: {user.email}</p>
      <p>
        Email Verified: &nbsp;
        {user.isVerified ? '✅' : '❌' }
      </p>
      <p>
        Admin: &nbsp;
        {user.admin ? '✅' : '❌' }
      </p>
    </Centered>
  )
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  assessments: state.assessments
});

export default connect(mapStateToProps)(Profile);
