import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// MUI Imports
import { CircularProgress } from "@material-ui/core";
import { getToken } from "../../actions";

const RedirectMyer = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [token, setToken] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId");
  useEffect(() => {
    if (userId) {
      setLoading(true);
      getToken(userId)
        .then((response) => {
          console.log(response);
          if (response.accessToken) {
            setToken(response.accessToken);
          } else if (response.message) {
            setError(response.message);
          }
        })
        .catch((err) => {
          console.log(err.message);

          setError("something went wrong. Please try again later");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (token) {
      document.hiddenform.submit();
    }
  }, [token]);

  // useEffect(() => {
  //   const redirectToMyerBrigg = async () => {
  //     let payload = {
  //       bearer_token: token,
  //       resource_url: "https://demo-elevate.themyersbriggs.com/",
  //     };
  //     const headers = {
  //       // "Content-Type": "application/json",
  //       "Content-Type": "multipart/form-data",
  //       "Access-Control-Allow-Origin": "*", // Include this header for CORS
  //     };
  //     try {
  //       // const response =
  //       await axios.post(
  //         `https://demo-login.themyersbriggs.com/Auth/Login`,
  //         payload,
  //         { headers: headers }
  //       );

  //       // return response.data;
  //     } catch (error) {
  //       return error.message;
  //     }
  //   };

  //   token && redirectToMyerBrigg();
  // }, [token]);

  return (
    <div>
      {loading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            marginTop: "12px",
          }}
        >
          <CircularProgress />
          <div
            style={{
              paddingTop: "12px",
            }}
          >
            Please wait while we log you in Myers Briggs
          </div>
        </div>
      )}
      {error && <div>{error}</div>}

      <form
        style={{ display: "none" }}
        method="POST"
        name="hiddenform"
        // action="https://demo-login.themyersbriggs.com/Auth/Login"
        action="https://login.themyersbriggs.com/Auth/Login"
      >
        <input type="hidden" name="bearer_token" value={token} />
        <input
          type="hidden"
          name="resource_url"
          // value="https://demo-elevate.themyersbriggs.com"
          value="https://elevate.themyersbriggs.com"
        />
        <input
          type="hidden"
          name="returnUrl"
          // value="http://localhost:3000/assess/myers-briggs"
          value="https://www.wanderlustcareerstesting.com/assess/myers-briggs"
        />
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
};

export default RedirectMyer;
