import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

// MUI Imports
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/material";
import WestIcon from "@mui/icons-material/West";

import SingleRecord from "./SingleRecord";
import SingleSelect from "../SearchRecord/SingleSelect";
import { getFilteredRecords, getRecordOptions } from "../../../actions";
import { connect } from "react-redux";
import Centered from "../../layout/Centered";

// images
import BackgroundImage from "../../../images/graphics/admin-record-bg.png";

// Styles
const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "grid",
    gridTemplateColumns: " repeat(3, minmax(0, 1fr))",
    width: "100%",
  },

  selectedValuesContainer: {
    display: "flex",
    overflowX: "auto",
    width: "100%",
    gap: "15px",
  },

  heading: {
    fontSize: "46px",
    fontWeight: "500",
    color: "#7D80C6",
    textAlign: "center",
  },
  label: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#383050",
    marginBottom: "6px",
  },
  typeHeading: {
    fontSize: "36px",
    fontWeight: "400",
    color: "#383050",
    marginTop: "60px",
    marginBottom: "24px",
  },

  recordTypeHeading: {
    fontSize: "36px",
    fontWeight: "400",
    color: "#383050",
    margin: "16px 0",
  },

  // selectedValuesBox: {
  //   fontFamily: "Lato",
  //   minWidth: "215px",
  //   minHeight: "45px",
  //   borderRadius: " 4px",
  //   backgroundColor: " white",
  //   paddingLeft: "16px",
  //   paddingRight: "16px",
  //   paddingTop: "12px",
  //   paddingBottom: "12px",
  //   border: "1px solid #383050",
  //   height: "max-content",
  //   [theme.breakpoints.down("lg")]: {
  //     minWidth: "155px",
  //     paddingTop: "10px",
  //     paddingBottom: "10px",
  //     minHeight: "41px",
  //   },
  // },

  backIcon: {
    fontSize: "2.3rem !important",
    color: "#7D80C6 !important",
  },

  logo: {
    width: "112px",
    marginLeft: "6px",
  },

  typeContainer: {
    gridTemplateColumns: " repeat(6, minmax(0, 1fr))",
    display: "grid",
    gap: "17px",
    [theme.breakpoints.down("lg")]: {
      gap: "10px",
    },
  },
  searchBtn: {
    padding: "12px 40px",
    fontWeight: "500",
    fontSize: "14px",
    fontFamily: "Roboto",
    backgroundColor: "#7D80C6",
    color: "white",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RecordResult = (props) => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const { loading, records, filters } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [searchOptions, setSearchOptions] = useState({
    MBTI_1: query.get("MBTI_1"),
    MBTI_2: query.get("MBTI_2"),
    MBTI_3: query.get("MBTI_3"),
    SII_1: query.get("SII_1"),
    SII_2: query.get("SII_2"),
    SII_3: query.get("SII_3"),
    salary: query.get("salary"),
    min_edu: query.get("min_edu"),
    familyType: query.get("familyType"),
    stress_level: query.get("stress_level"),
  });

  const selectOnChangeHandler = (name, value) => {
    setErrorMessage("");
    const newSearchOptions = {
      ...searchOptions,
      [name]: value,
    };

    setSearchOptions(newSearchOptions);

    // Update the URL query parameters without pushing a new entry to history
    const searchParams = new URLSearchParams(newSearchOptions);
    history.replace({
      search: searchParams.toString(),
    });
  };

  const resetFilterHandler = () => {
    const newSearchOptions = {
      MBTI_1: "",
      MBTI_2: "",
      MBTI_3: "",
      SII_1: "",
      SII_2: "",
      SII_3: "",
      salary: "",
      min_edu: "",
      familyType: "",
      stress_level: "",
    };

    setSearchOptions(newSearchOptions);

    // Update the URL query parameters without pushing a new entry to history
    const searchParams = new URLSearchParams(newSearchOptions);
    history.replace({
      search: searchParams.toString(),
    });
  };

  // atleast one filter should be selected
  const checkSelectedOptions = () => {
    const filterChecked = Object.keys(searchOptions).some(
      (key) => searchOptions[key] !== ""
    );
    return filterChecked;
  };

  // const redirectToResults = () => {
  //   if (!checkSelectedOptions()) {
  //     setErrorMessage("Atleast one filter is required");
  //     return;
  //   }

  const searchResultHandler = () => {
    if (!checkSelectedOptions()) {
      setErrorMessage("Atleast one filter is required");
      return;
    }
    props.getFilteredRecords(searchOptions);
  };
  useEffect(() => {
    props.getRecordOptions();
    props.getFilteredRecords(searchOptions);
  }, []);

  useEffect(() => {
    console.log(searchOptions);
  }, [searchOptions]);

  return (
    <Box
      sx={{
        paddingY: "30px",
        paddingX: "24px",
        minHeight: "100vh",
        fontFamily: "Bebas Neue",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      />
      <div
        style={{
          position: "relative",
          zIndex: 10,
          backgroundColor: "transparent",
        }}
      >
        <div className={classes.headerContainer}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link to="/admin/search-record">
              <WestIcon fontSize="inherit" className={classes.backIcon} />
            </Link>
          </div>
          <div className={classes.heading}>Data-Driven Career Ideas</div>
          <div />
        </div>

        <h2 className={classes.typeHeading}>SUMMARY</h2>

        {/* <div className={classes.selectedValuesContainer}>
          <div>
            <h3 className={classes.label}>SII CODE</h3>
            <div
              className={`${classes.selectedValuesBox}`}
              style={{ textTransform: "uppercase" }}
            >
              {searchOptions.SII_1 +
                searchOptions.SII_2 +
                searchOptions.SII_3 || ""}
            </div>
          </div>
          <div>
            <h3 className={classes.label}>MBTI CODE</h3>
            <div className={`${classes.selectedValuesBox}`}>
              {searchOptions.MBTI_1 +
                searchOptions.MBTI_2 +
                searchOptions.MBTI_3 || ""}
            </div>
          </div>
          <div>
            <h3 className={classes.label}>Salary Level</h3>
            <div className={`${classes.selectedValuesBox}`}>
              {searchOptions.salary}
            </div>
          </div>
          <div>
            <h3 className={classes.label}>Education</h3>
            <div className={`${classes.selectedValuesBox}`}>
              {searchOptions.min_edu}
            </div>
          </div>
          <div>
            <h3 className={classes.label}>Stress Level</h3>
            <div className={`${classes.selectedValuesBox}`}>
              {searchOptions.stress_level}
            </div>
          </div>
        </div> */}

        <div>
          <div className={classes.upperContainer}>
            {/* SII code fields */}

            <div style={{ width: "100%" }}>
              <div className={classes.typeContainer}>
                <SingleSelect
                  label="Interest 1"
                  name="SII_1"
                  selectValue={searchOptions.SII_1}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.SII_1}
                />
                <SingleSelect
                  label="Interest 2"
                  name="SII_2"
                  selectValue={searchOptions.SII_2}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.SII_2}
                />
                <SingleSelect
                  label="Interest 3"
                  name="SII_3"
                  selectValue={searchOptions.SII_3}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.SII_3}
                />

                {/* MBTI code fields */}
                <SingleSelect
                  label="Introvert/Extrovert"
                  name="MBTI_1"
                  selectValue={searchOptions.MBTI_1}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.MBTI_1}
                />

                <SingleSelect
                  label="Motivation"
                  name="MBTI_2"
                  selectValue={searchOptions.MBTI_2}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.MBTI_2}
                />

                <SingleSelect
                  label="Judging/Perceiving"
                  name="MBTI_3"
                  selectValue={searchOptions.MBTI_3}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.MBTI_3}
                />
              </div>

              <div className={classes.typeContainer}>
                <SingleSelect
                  label="Salary Level"
                  name="salary"
                  selectValue={searchOptions.salary}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.salary}
                />

                <SingleSelect
                  label="Education"
                  name="min_edu"
                  selectValue={searchOptions.min_edu}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.min_edu}
                />

                <SingleSelect
                  label="Stress Level"
                  name="stress_level"
                  selectValue={searchOptions.stress_level}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.stress_level}
                />

                <SingleSelect
                  label="Job Family"
                  name="familyType"
                  selectValue={searchOptions.familyType}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.familyType}
                />
                <div />
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    gap: "6px",
                  }}
                >
                  <button
                    className={classes.searchBtn}
                    onClick={resetFilterHandler}
                  >
                    Reset
                  </button>
                  <button
                    className={classes.searchBtn}
                    onClick={searchResultHandler}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {errorMessage && (
          <div
            style={{
              fontFamily: "Roboto",
              color: "red",
              fontSize: "14px",
              paddingTop: "8px",
              textAlign: "center",
            }}
          >
            {errorMessage}
          </div>
        )}
        <hr
          style={{
            marginTop: "24px",
            marginBottom: "24px",
            borderLeft: "0px",
            borderRight: "0px",
            borderBottom: "0px",
            borderTop: "1px solid #383050",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "22px",
            paddingBottom: "24px",
          }}
        >
          {loading && (
            <Centered>
              <CircularProgress style={{ color: "#7D80C6" }} />
            </Centered>
          )}

          {!loading &&
            records?.exactMatch?.length === 0 &&
            records?.suggestion?.length === 0 && <div>No records found</div>}

          {records?.exactMatch?.length > 0 && !loading && (
            <Fragment>
              <h2 className={classes.recordTypeHeading}>Matches</h2>
              {records.exactMatch.map((data, index) => {
                return <SingleRecord key={index} record={data} />;
              })}
            </Fragment>
          )}

          {records?.suggestion?.length > 0 && !loading && (
            <Fragment>
              <h2 className={classes.recordTypeHeading}>Suggestions</h2>
              {records.suggestion.map((data, index) => {
                return <SingleRecord key={index} record={data} />;
              })}
            </Fragment>
          )}
        </div>
      </div>
    </Box>
  );
};

const mapDispatchToProps = {
  getFilteredRecords,
  getRecordOptions,
};

const mapStateToProps = (state) => {
  return {
    records: state.records.records,
    loading: state.records.loading,
    filters: state.records.filters,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordResult);
