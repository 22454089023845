import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import isEmpty from "is-empty";
import { colors } from "../../../constants/colors";

// My Imports
import Centered from "../../layout/Centered";
import Card from "./Card";

// MUI Imports
import { CircularProgress, Typography, LinearProgress } from '@material-ui/core';

// Actions
import {
  fetchAnswers, reorderCard, moveCard
} from '../../../actions/index';

const CardSort = (props) => {
  const { bins, rankMode } = props

  // Styles
  const useStyles = makeStyles({
    root: {
      // backgroundColor: 'rgb(228, 246, 194)',
      display: 'flex',
      flex: '1 0 auto',
      flexWrap: 'nowrap'
    },
    columnName: {
      padding: '10px',
      paddingBottom: '0px',
      fontSize: '1.1em',
      fontWeight: 700
    },
    columnContainer: {
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'column',
      width: '16.6%',
    },
    aboveColumn: {
      padding: '0 10px',
      display: 'flex',
      justifyContent: 'center',
      '& div': {
        minHeight: '20px'
      }
    },
    column: {
      backgroundColor: '#ebecf0',
      // padding: '10px',
      margin: '5px',
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'column',
      borderRadius: '6px',
      height: '0px',
      userSelect: 'none',
      overflow: 'hidden'
    },
    dropZone: {
      minHeight: '100px',
      padding: '10px',
      flex: '1 1 auto',
      overflow: 'scroll'
    },
    draggingOver: {
      // backgroundColor: 'red'
    }
  });

  const classes = useStyles();

  useEffect(() => {
    console.log('useEffect! props.answers:', props.answers)
    if (isEmpty(props.answers) || props.answers.answers === undefined) {
      console.log('Answers empty... attempting to fetch user\'s answers')
      // on load fetch already completed answers if there are any
      let answerData = {
        userId: props.user._id,
        assessmentId: props.assessment._id
      }
      props.fetchAnswers(answerData)
    }
  }, []);

  const move = (source, destination, droppableSource, droppableDestination) => {
    console.log('move:', source, destination, droppableSource, droppableDestination)
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const moveCardTo = (source, destination) => {
    // move - source, destination, droppableSource, droppableDestination
      // source - array of cards in source bin
      // destination - array of cards in dest bin
      // droppableSource = { index: 1, droppableId: 'start'}
      // droppableDestination
    const answers = props.answers.answers
    const sourceId = source.droppableId
    const destId = destination.droppableId
    const newCardData = move(answers[sourceId].cards, answers[destId].cards, source, destination)
    props.moveCard(props.assessment._id, newCardData, sourceId, destId)
  }

  const onDragEnd = (result) => {
    const { source, destination } = result;
    console.log(source, destination)

    // dropped outside the list
    if (!destination) {
      return;
    }

    const sourceId = source.droppableId
    const destId = destination.droppableId

    const answers = props.answers.answers

    // Moved within same list
    if (sourceId === destId) {
      const newListData = reorder(
        answers[sourceId].cards, // list being reordered
        source.index, // start index
        destination.index // end index
      );
      props.reorderCard(props.assessment._id, sourceId, newListData)
    } else {
      const newCardData = move(answers[sourceId].cards, answers[destId].cards, source, destination)
      props.moveCard(props.assessment._id, newCardData, sourceId, destId)
    }
  }

  const renderStars = (binId) => {
    switch (binId) {
      case 'always':
        return(<div>⭐⭐⭐⭐</div>)
      case 'often':
        return(<div>⭐⭐⭐</div>)
      case 'sometimes':
        return(<div>⭐⭐</div>)
      case 'seldom':
        return(<div>⭐</div>)
      case 'never':
        return(<div></div>)
      default:
        return('')
    }
  }

  if (props.answers?.answers && Object.keys(props.answers.answers).length > 0) {
    const answers = props.answers.answers
    // const bins = props.assessment.questions.valueBins.allIds

    const CardList = React.memo(function CardList({ cards }) {
      // CREATE CARDS FOR EACH LIST
      return cards.map((card, index) => (
        <Card card={card} index={index} key={card.id}
          showNumbers={rankMode}
          move={move}
          moveCardTo={moveCardTo}
        />
      ));
    });

    return (
      <div className={classes.root}>
        <DragDropContext onDragEnd={onDragEnd}>

          {/* CREATE LISTS */}
          {bins.map((binId, index) => {
            const bin = props.assessment.questions.valueBins.byId[binId]
            return(
              // COLUMN
              <div key={index} className={classes.columnContainer}>
                <div className={classes.aboveColumn}>
                  { renderStars(binId) }
                </div>
                <div className={classes.column}>
                  <Typography variant="h6" className={classes.columnName}>
                    {bin.name}
                  </Typography>
                  <Droppable droppableId={binId}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}
                        className={`${classes.dropZone} ${snapshot.isDraggingOver && classes.draggingOver}`}
                      >
                        <CardList cards={answers[binId].cards} />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  {bin.maxCards && !rankMode &&
                    <ColumnFooter min={bin.minCards} max={bin.maxCards} cards={answers[binId].cards.length} />
                  }
                </div>
              </div>
            )
          })}
        </DragDropContext>
      </div>
    )
  } else {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    )
  }
}

const ColumnFooter = (props) => {
  const { min, max, cards } = props
  const full = cards === max
  const over = cards > max
  const progress = cards >= max ? 100 : cards / max * 100

  // Styles
  const useStyles = makeStyles({
    root: {
      backgroundColor: '#e0e1e6',
      padding: '10px',
      paddingTop: '6px',
      borderTop: '1px solid rgb(214, 214, 214)'
    },
    minMax: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    bar: {
      // backgroundColor: 'rgba(161, 255, 4, 0.31)',
    },
    full: {
      '& div': {
        backgroundColor: colors.green,
      }
    },
    over: {
      '& div': {
        backgroundColor: colors.red,
      }
    }
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.minMax}>
        <div>
          {min && `Min: ${min}`}
        </div>
        <div>
          Max: {cards}/{max}
        </div>
      </div>
      <LinearProgress variant="determinate" value={progress}
        className={`${classes.bar} ${full && classes.full} ${over && classes.over}`}
      />
    </div>
  )
}

const mapDispatchToProps = {
  reorderCard,
  moveCard,
  fetchAnswers
};

const mapStateToProps = state => {
  return {
    user: state.user,
    assessment: state.assessments.byId['career-values'],
    answers: state.answers.byId['career-values']
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardSort);
