import produce from 'immer';

import { SET_USER_DATA } from "../actions/index";

const initialState = {
  byId: {},
  allIds: []
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {

      case SET_USER_DATA:
        if (action.data.plans[0]) {
          let byId = {}
          let allIds = []

          // update assessmentMetadata with any user progress from assessmentMetadata
          let userAssessmentData = action.data.assessmentData
          if (userAssessmentData) {
            userAssessmentData.forEach(assessment => {
              const { _id, results } = assessment
              if (results) {
                byId[_id] = results
                allIds.push(_id)
              }
            })
          }

          draft.byId = byId
          draft.allIds = allIds

          return draft
        } else {
          return draft
        }

    default:
      break;
    }
  });
