import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Mixpanel } from "../../utils/mixpanel";


// MUI Imports
import {
  Container, Typography
} from '@material-ui/core';

import Centered from '../layout/Centered';
import Contact from '../landingPage/Contact';

// Actions
import {
  sendFlash
} from '../../actions/index';

// My Imports
import Plans from './Plans';
import Grid from '../landingPage/Grid';

const useStyles = makeStyles({
  root: {},
  section: {
    padding: '50px 0',
    '& h3': {
      fontWeight: 800,
      fontSize: '2.5em',
      marginBottom: '30px'
    }
  }
});

const ChoosePlan = (props) => {
  const classes = useStyles();
  const [openContactForm, setOpenContactForm] = useState(false);
  const { user } = props

  // Load plans into redux
  useEffect(() => {
    // redirect to "/" if user is not authenticated
    // if (!props.auth.isAuthenticated) {
    //   props.history.push("/");
    // }

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("canceled")) {
      props.sendFlash(
        "Order canceled -- continue to shop around and checkout when you're ready.", "warning"
      );
    }
  }, []);

  const handleOpenContactForm = () => {
    Mixpanel.track("Opened Contact Form (App)")
    setOpenContactForm(true)
  }

  const handleSelect = (planName, planId, price) => {
    // if user isnt logged in
    if (!props.auth.isAuthenticated) {
      console.log('user not logged in')
      // console.log(planName, planId, price)

      Mixpanel.track("Chose a Plan", {
        name: planName,
        id: planId,
        price: price
      });

      // redirect to /register and pass along plan selection data
      props.history.push('/register', {
        planName: planName,
        planId: planId,
        price: price
      });

    // If user is logged in (Upgrade Path)
    } else {
      if (planId === 'free') {
        props.history.push("/")
        props.sendFlash("Free plan initialized!", "success")
        Mixpanel.track("Initialized Free Plan");
      } else {
        Mixpanel.track("Viewed Checkout", {
          planName: planName,
          planId: planId,
          price: price
        });

        props.history.push("/checkout", {
          planName: planName,
          planId: planId,
          price: price
        })
      }
    }
  }

  return(
    <>
      <div className={classes.section} style={{ backgroundColor: 'rgb(230, 238, 240)' }}>
        <Container maxWidth='lg' className={classes.root}>
          <Typography variant='h3' gutterBottom align='center'>
            Choose The Plan That's Right For You
          </Typography>


          <Typography gutterBottom align='center' style={{
            marginTop: '-10px',
            marginBottom: '30px'
          }}>
            {props.auth.isAuthenticated && user.plans ? (
            `Your Current Plan: ${user.plans[0].name}`
          ) : (
            'Please select a plan below to start testing today!'
          )}
          </Typography>


          <Plans cta='Choose' onSelect={handleSelect} />
        </Container>
      </div>
      <div className={classes.section}>
        <Typography variant='h3' gutterBottom align='center'>
          A Detailed Breakdown of Our Plans
        </Typography>
        <Container maxWidth='md' style={{
          marginTop: '30px',
          marginBottom: '30px'
        }}>
          <Grid
            showActionButtons={false}
            actionButtonText={'Choose'}
            history={props.history}
            showContact={true}
            handleOpenContactForm={handleOpenContactForm}
          />
        </Container>
      </div>
      <Contact open={openContactForm} setOpen={setOpenContactForm} />
    </>
  )
}

const mapDispatchToProps = {
  sendFlash
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChoosePlan));
