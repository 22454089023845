import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEmpty from "is-empty";
import { motion } from "framer-motion"

// MUI Imports
import {
  Typography, Button, CircularProgress
} from '@material-ui/core';

import Centered from '../layout/Centered';

// Actions
import {
  fetchPlans, sendFlash, handlePaymentRedirect
} from '../../actions/index';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 780px)' : {
      flexDirection: 'column'
    }
  },
  plan: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1 1 25%',
    maxWidth: '500px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    borderRadius: '10px',
    margin: '5px',
    marginBottom: '10px',
    padding: '20px',
    // transition: { duration: 0.5 },
    '& svg': {
      fontSize: '4em'
    }
  },
  planName: {
    margin: '10px',
    marginBottom: '20px'
  },
  price: {
    margin: '20px 20px'
  },
  list: {
    // listStyle: 'none',
    paddingLeft: '20px',
    '& li::marker': {
      color: '#0000008a'
    }
  },
  bullet: {
    fontSize: '0.9em'
  },
  processingFeeNote: {
    fontSize: "10px", 
  },
  spacer: {
    height: "15px"
  }
});

const Plans = (props) => {
  const classes = useStyles();
  let { loading, plans, cta, userPlan } = props

  plans = plans.sort((a, b) => (a.order > b.order) ? 1 : -1)

  // remove irrelevant plans (plan user currently has and cheaper plans)
  if (userPlan) {
    plans = plans.filter(plan => plan.price > userPlan.price)
  }

  // Load plans into redux
  useEffect(() => {
    if (!props.loading && plans.length === 0) {
      console.log('loading plans...')
      props.fetchPlans()
    }

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("canceled")) {
      props.sendFlash(
        "Order canceled -- continue to shop around and checkout when you're ready.", "warning"
      );
    }
  }, []);

  const calcPrice = (currentPlan, newPlan) => {
    if (!userPlan || currentPlan._id === 'free') {
      return newPlan.price
    } else {
      const alreadyPaid = currentPlan.price
      return newPlan.price - alreadyPaid
    }
  }

  if (!loading) {
    return(
      <div className={classes.root}>
        {plans.map((plan, index) => (
          <motion.div key={plan.name} className={classes.plan}
            whileHover={{
              y: -10,
              backgroundColor: 'rgba(255, 255, 255, 1)',
              // transition: { duration: 0.2 }
            }}
          >
            <div>
              <Typography variant='h4' gutterBottom align='center'
                className={classes.planName}
              >
                {plan.name}
              </Typography>

              <ul className={classes.list}>
                {plan.features.map((feature, index) => (
                  <li key={index}>
                    <Typography color="textSecondary" className={classes.bullet}>
                      {feature}
                    </Typography>
                  </li>
                ))}
              </ul>

            </div>
            <div>
              <Typography variant='h4' align='center' className={classes.price} gutterBottom>
                {`$${calcPrice(userPlan, plan) / 100}`}

                {
                  plan._id != "free" ?
                  <Typography className={classes.processingFeeNote} color="textSecondary"
                   align='center'
                  >
                    + {plan.processingFeePercentage}% processing fee
                  </Typography>
                :
                  <div className={classes.spacer}></div>
                }

              </Typography>

              <Button
                // variant="contained"
                color="primary" size='large' fullWidth
                onClick={() => props.onSelect(plan.name, plan._id, `$${calcPrice(userPlan, plan) / 100}`)}
              >
                {cta}
              </Button>
            </div>
          </motion.div>
        ))}
      </div>
    )
  } else {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    )
  }
}

const mapDispatchToProps = {
  fetchPlans,
  sendFlash,
  handlePaymentRedirect
};

const mapStateToProps = state => {
  const user = isEmpty(state.user) ? false :
    state.user.plans ? state.user.plans[0] : false
  return {
    userPlan: user,
    loading: state.plans.loading,
    plans: state.plans.allPlans
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Plans));
