import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { colors } from "../../constants/colors";
import { motion } from "framer-motion";

// MUI Imports
import {
  ListItem, ListItemIcon, ListItemText, CircularProgress, Typography
} from '@material-ui/core';

// Icons
import LabelIcon from '@material-ui/icons/Label';
import CheckIcon from '@material-ui/icons/CheckCircle';
import TimeIcon from '@material-ui/icons/AccessTime';
import InfoIcon from '@material-ui/icons/Info';

import CustomTooltip from "../layout/CustomTooltip";

// Convert icon components into motion components
const MotionLabelIcon = motion(LabelIcon)
const MotionCheckIcon = motion(CheckIcon)
const MotionLink = motion(Link)


const useStyles = makeStyles({
  root: {
    minHeight: '70px',
    padding: '8px 20px',
    '&.Mui-disabled': {
      opacity: 1,
      '& div.MuiListItemText-root': {
        opacity: 0.5
      },
    },
    '.Mui-disabled:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  text: {
    '& .MuiListItemText-primary': {
      fontSize: '1.4em'
    },
    '& .MuiListItemText-secondary': {
      fontSize: '1.1em'
    }
  },
  icon: {
    width: '1.5em',
    height: '1.5em'
  },
  checkIcon: {
    color: colors.green,
  },
  inProgressIcon: {
    color: colors.blue,
    transform: "rotate(-180deg)"
  },
  notStartedIcon: {
    color: colors.blue
  },
  circularProgress: {
    zIndex: 1
  },
  circularProgressBkg: {
    position: 'absolute',
    '& svg': {
      color: 'rgba(0, 0, 0, 0.05)'
    }
  },
  timeLine: {
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '1em',
      marginRight: '5px'
    }
  },
  infoIcon: {
    opacity: 0.3
  }
});

const Assessment = (props) => {
  const classes = useStyles();

  const AssessmentStatusIcon = () => {

    const hoverMotionLabel = {
      hover: { x: 8 }
    };

    const hoverMotionCheck = {
      hover: { scale: 1.1 }
    };

    if (props.completed) {
      return (
        <MotionCheckIcon
          variants={hoverMotionCheck}
          className={`${classes.icon} ${classes.checkIcon}`}
        />
      )
    } else {
      if (props.percentComplete === 0) {
        return (
          <MotionLabelIcon
            variants={hoverMotionLabel}
            className={`${classes.icon} ${classes.notStartedIcon}`}
          />
        )
      } else {
        return (
          <>
            <CircularProgress
              variant="determinate"
              size={30}
              value={props.percentComplete}
              thickness={4}
              className={classes.circularProgress}
            />
            <CircularProgress
              variant="determinate"
              size={30}
              value={100}
              thickness={4}
              className={classes.circularProgressBkg}
            />
          </>
        )
      }
    }
  }

  const SecondaryText = () => {
    if (!props.completed && props.percentComplete) {
      return `${props.percentComplete.toFixed(0)}% Complete`
    } else if (props.completed) {
      return false
    } else {
      return (
        <div className={classes.timeLine}>
          <TimeIcon />
          {props.contentfulData.timeToComplete || 'Time to complete not available' }
        </div>
      )
    }
  }

  const InfoTooltip = () => (
    <CustomTooltip placement='right' arrow
      title={
        <div>
          <Typography gutterBottom>
            { props.contentfulData.shortDescription || 'No description available' }
          </Typography>

          { !props.completed && props.percentComplete > 0 &&
            <>
              <Typography style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginTop: '10px'
              }}>
                <TimeIcon style={{
                  fontSize: '1em',
                  marginRight: '5px'
                }} />
                { props.contentfulData.timeToComplete || 'Time to complete not available' }
              </Typography>
            </>
          }
        </div>
      }
    >
      <InfoIcon className={classes.infoIcon} />
    </CustomTooltip>
  )

  return(
    <ListItem button
      component={props.disabled ? motion.div : MotionLink}
      to={props.link}
      className={classes.root}
      disabled={props.greyedOut}
      whileHover='hover'
    >
      <ListItemIcon>
        <AssessmentStatusIcon />
      </ListItemIcon>
      <ListItemText
        primary={props.name}
        secondary={<SecondaryText />}
        secondaryTypographyProps={{ component: 'div' }}
        className={classes.text}
      />
      <InfoTooltip />
    </ListItem>
  )
}

export default Assessment;
