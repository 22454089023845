import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import checkAuthToken from "./utils/checkAuthToken";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/client/react";

import { Provider } from "react-redux";
// import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import { MuiThemeProvider } from "@material-ui/core";

import { createTheme } from "@mui/material/styles";
import { theme } from "./constants/theme";
import store from "./store";

// My Components
import Navbar from "./components/layout/Navbar";
import Landing from "./components/main/Landing";
import SignUp from "./components/auth/SignUp";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Flash from "./components/widgits/Flash";
import Profile from "./components/layout/Profile";
import UserResults from "./components/main/UserResults";
import DemoResults from "./components/results/DemoResults";
import AdminResults from "./components/admin/AdminResults";
import InterviewAnswers from "./components/admin/InterviewAnswers";
import Admin from "./components/admin/Admin";
import ScrollToTop from "./components/helpers/ScrollToTop";

import PrivateRoute from "./components/helpers/PrivateRoute";
import AdminRoute from "./components/helpers/AdminRoute";
import Consent from "./components/assessments/Consent";
import Depression from "./components/assessments/Depression";
import MyersBriggs from "./components/assessments/MyersBriggs";
import Neo from "./components/assessments/Neo";
import Interview from "./components/assessments/Interview";
import CardSort from "./components/assessments/card-sort/CareerValues";
import CardSortRank from "./components/assessments/card-sort/TopValues";
import ChoosePlan from "./components/main/ChoosePlan";
import Fonts from "./components/helpers/Fonts";
import Test from "./components/helpers/Test";
import NotFound from "./components/layout/NotFound";
import LandingPage from "./components/landingPage/LandingPage";
import Checkout from "./components/main/Checkout";
import RedirectMyer from "./components/assessments/RedirectMyer";
import AddRecord from "./components/admin/AddRecord";
import SearchRecords from "./components/admin/SearchRecord";
import RecordResult from "./components/admin/RecordResult/RecordResult";

// Check for token to keep user logged in
checkAuthToken();

// Apollo GraphQL wrapper initialization
const spaceId = "du1mp4ydbrwv";
const apiKey = "CNzfJdGX3PHHPE-T794lOLNfCFW-PVBoIMfLBHDS0uk";

const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${spaceId}?access_token=${apiKey}`,
  cache: new InMemoryCache(),
});

const App = (props) => {
  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <CssBaseline />
      <Provider store={store}>
        <ApolloProvider client={client}>
          <Router>
            <ScrollToTop />
            <Navbar />

            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/register" component={SignUp} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot" component={ForgotPassword} />
              <Route exact path="/reset/:token" component={ResetPassword} />

              {/* Test Page */}
              <Route exact path="/test" component={Test} />
              <Route exact path="/fonts" component={Fonts} />
              <Route exact path="/landing" component={LandingPage} />

              <Route exact path="/plans" component={ChoosePlan} />
              <Route exact path="/results/demo" component={DemoResults} />

              <PrivateRoute exact path="/profile" component={Profile} />
              <PrivateRoute exact path="/results" component={UserResults} />
              <PrivateRoute exact path="/checkout" component={Checkout} />

              {/* Assessments */}
              <PrivateRoute
                exact
                path="/assess/consent"
                component={Consent}
                redirect={true}
              />
              <PrivateRoute
                exact
                path="/assess/depression-screening"
                component={Depression}
                redirect={true}
              />
              <PrivateRoute
                exact
                path="/assess/interview"
                component={Interview}
                redirect={true}
              />
              <PrivateRoute
                exact
                path="/assess/neo"
                component={Neo}
                redirect={true}
              />
              <PrivateRoute
                exact
                path="/assess/myers-briggs"
                component={MyersBriggs}
                redirect={true}
              />
              <PrivateRoute
                exact
                path="/assess/career-values"
                component={CardSort}
                redirect={true}
              />
              <PrivateRoute
                exact
                path="/assess/career-values-rank"
                component={CardSortRank}
                redirect={true}
              />
              <PrivateRoute
                exact
                path="/myer-briggs"
                component={RedirectMyer}
                redirect={true}
              />

              {/* Admin Only Access */}
              <AdminRoute exact path="/results/:id" component={AdminResults} />
              <AdminRoute
                exact
                path="/interview/:id"
                component={InterviewAnswers}
              />
              <AdminRoute exact path="/admin" component={Admin} />
              {/* <Route exact path="/admin/interests" component={Interest} />
              <Route exact path="/admin/database" component={Database} /> */}


              <Route exact path="/admin/add-record" component={AddRecord} />
              <Route exact path="/admin/search-record" component={SearchRecords} />
              <Route exact path="/admin/search-record/result" component={RecordResult} />


              {/* No Match -> 404 */}
              <Route path="*" component={NotFound} />
            </Switch>

            <Flash />
          </Router>
        </ApolloProvider>
      </Provider>
    </MuiThemeProvider>
  );
};

export default App;
