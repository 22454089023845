import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// My Imports
import FullPageBkg from "../layout/FullPageBkg";

// MUI Imports
import { Container, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles({
  root: {},
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px'
  }
});

const NotFound = (props) => {
  const classes = useStyles();

  return(
    <FullPageBkg>
      <Container maxWidth='md' className={classes.root}>
        <Typography variant='h1' align='center'>
          404 😭
        </Typography>
        <Typography variant='h5' align='center'>
          Page Not Found
        </Typography>
        <div className={classes.buttonBox}>
          <Button variant="contained" component={Link} to="/" color="primary">
            Go Home
          </Button>
        </div>
      </Container>
    </FullPageBkg>
  )
}

export default NotFound;
