import axios from "axios";
import { getAllUsers, sendFlash } from "../actions/index";
import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.NODE_ENV !== 'development' ?
  "pk_live_JJ4K2vqnuhj9B2aUIc5L0yG8" :
  "pk_test_Z6TGaULGoks9ZDvvKBrjRcjN");

export const SET_PLANS_LOADING = "SET_PLANS_LOADING";
export const SET_PLAN_DATA = "SET_PLAN_DATA";
export const UPDATE_USER_PLAN = "UPDATE_USER_PLAN";

// show spinner
export const setPlansLoading = () => {
  return {
    type: SET_PLANS_LOADING
  };
};

// data via API -> update redux state
export const setPlanData = (data) => {
  return {
    type: SET_PLAN_DATA,
    data: data
  };
};

// Fetch user data from backend
export const fetchPlans = () => dispatch => {
  dispatch(setPlansLoading())
  axios.get("/api/plans")
  .then(
    response => {
      dispatch(setPlanData(response.data));
    },
    error => {
      console.log(error)
    }
  )
};


// Redirect to Stripe checkout page
export const handlePaymentRedirect = (planId) => async dispatch => {
  console.log('handlePaymentRedirect planId:', planId)
  const stripe = await stripePromise;
  const response = await axios.post("/api/checkout", { planId: planId })

  const session = response.data
  const result = await stripe.redirectToCheckout({
    sessionId: session.id,
  });

  if (result.error) {
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
    dispatch(sendFlash(result.error.message, "error"))
  }
};


// API call to set user plan (manual plan override wihout payment)
export const requestSetUserPlan = (planData) => dispatch => {
  axios.post("/api/plans", planData)
  .then(response => {
    const { userId } = planData
    const { success, newPlanId, reason } = response.data
    if (success) {
      dispatch(getAllUsers()) // refreshes app data
      console.log(`Sucessfully changed plan to ${newPlanId}`)
    } else {
      console.log(`Plan change failed. Reason: ${reason}`)
    }
  },
  error => {
    console.log(error)
  }
)};
