import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// Images
import bkgImage from "../../images/scenic_road_1920.jpg";
import logo from "../../images/logo_white_200.png";

// MUI Imports
import { Container, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 0 auto'
  },
  logo: {
    position: 'fixed',
    top: 0,
    left: 0,
    padding: '20px',
    '& img': {
      height: '65px'
    }
  },
  bkg: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    backgroundColor: '#91a0ac',
    background: 'linear-gradient(170deg, #826ed4 0%, #3981c6 50%, #adcbe8 100%)',
    // background: `url(${bkgImage}) no-repeat center center fixed`, /* fallback */
    // backgroundImage: `url(${bkgImage}), linear-gradient(160deg, #3981c6, #aacbe4)`,
    backgroundSize: 'cover',
    transform: 'scale(1.1)',
    overflow: 'hidden',
    filter: 'blur(3px)'
  },
  box: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    padding: '50px',
    // paddingBottom: '40px',
    margin: '20px',
    borderRadius: '10px',
    backdropFilter: 'blur(10px)',
    boxShadow: '9px 3px 30px 0px rgba(0,0,0,0.1)',
  }
});

const FullPageBkg = (props) => {
  const classes = useStyles();

  return(
    <div className={classes.root}>

      {/* Background Image */}
      <div className={classes.bkg} />

      <div className={classes.logo}>
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>

      {/* Content Box */}
      <div className={classes.box}>
        {props.children}
      </div>
    </div>
  )
}

export default FullPageBkg;
