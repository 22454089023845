import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { globalStyles } from './constants/globalStyles';

// MUI Imports
import {
  Container, Typography
} from '@material-ui/core';

// Images
import noreen from "../../images/client_portraits/noreen.jpg";
import emily from "../../images/client_portraits/emily.jpg";
import charu from "../../images/client_portraits/charu.jpg";

const useGlobalStyles = makeStyles({
  ...globalStyles
});

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgb(255, 255, 255)',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    '@media (max-width: 780px)' : {
      flexDirection: 'column'
    }
  },
  item: {
    display: 'flex',
    flex: '1 1 33%',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '5px',
    padding: '30px',
    '& svg': {
      fontSize: '4em'
    }
  },
  portrait: {
    flex: '0 0 auto',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '100%',
    marginBottom: '20px',
    '& img': {
      maxWidth: '100%',
      borderRadius: '100%',
    }
  }
});

const Testimonials = (props) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const { data } = props
  const testimonials = data.contentCollection.items

  return(
    <div className={`${classes.root} ${globalClasses.section}`}>
      <Container maxWidth='lg'>
        <Typography variant='h3' align='center' gutterBottom>
          {/* Client Testimonials */}
          {data.sectionTitle}
        </Typography>

        <div className={classes.container}>

          {testimonials.map((item, index) => (
            <div className={classes.item} key={index}>
              <div className={classes.portrait}>
                <img src={`${item.image.url}`} />
              </div>
              <Typography variant='h5' align='center' gutterBottom>
                {item.name.toUpperCase()} / {item.city.toUpperCase()}
              </Typography>
              <Typography align='center' color="textSecondary">
                {item.quote}
              </Typography>
            </div>
          ))}

        </div>
      </Container>
    </div>
  )
}

export default Testimonials;
