import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import validator from "validator";

import ErrorIcon from "@material-ui/icons/Error";

// My Imports
import { shouldQuestionShow, getAnswer } from "./helpers";

// MUI Imports
import {
  FormControl,
  FormLabel,
  TextField,
  Paper,
  FormHelperText,
} from "@material-ui/core";

// Actions
import { updateAnswer } from "../../../actions/index";
import HoverDetails from "../HoverDetails";
import { NotShowingBubbles } from "../../../constants/notShowingBubble";

const useStyles = makeStyles({
  formControl: {
    display: "flex",
  },
  textField: {
    // display: "block",
    "& input": {
      paddingTop: "12px",
      fontSize: "1.4em",
      // width: "300px",
    },
  },
  error: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "1.4em",
      marginRight: "5px",
    },
  },
  helperText: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

const Text = (props) => {
  const classes = useStyles(props);
  const [errors, setErrors] = useState(false);
  const { number, item, userId, assessmentId, answer } = props;

  let rows = 0;
  let multiline = false;
  if (item.lines) {
    multiline = true;
    rows = item.lines;
  }

  const handleTextChange = (event) => {
    const questionNum = event.target.name;
    const answer = event.target.value;
    validate(event);
    props.updateAnswer(userId, assessmentId, questionNum, answer);
  };

  const validate = (event) => {
    const validationErrors = [];
    const characterLimit = item.characterLimit;
    const text = event.target.value;

    // Required
    // if (text.length < 1) {
    //   validationErrors.push("Field is required.");
    // }

    // Character Limit
    if (characterLimit && text.length > characterLimit) {
      validationErrors.push(
        `Text exceeds ${characterLimit} character limit by ${
          text.length - characterLimit
        } characters.`
      );
    }

    // Number
    if (item.validation === "number" && !validator.isInt(text)) {
      validationErrors.push("Not a valid number.");
    }

    if (validationErrors.length > 0) {
      setErrors([...validationErrors]);
    } else {
      setErrors(false);
    }
  };

  if (props.show) {
    return (
      <Paper className={props.questionStyles}>
        <FormControl className={classes.formControl}>
          <FormLabel>
            {item.question}
            {NotShowingBubbles.includes(item.question) ||
            item.description === "" ? (
              ""
            ) : (
              <HoverDetails description={item.description} />
            )}
          </FormLabel>
          <TextField
            name={`${number}`}
            label={false}
            fullWidth={multiline ? true : false}
            variant="filled"
            multiline={multiline}
            minRows={rows}
            className={classes.textField}
            value={answer}
            onChange={handleTextChange}
            // removing to not show error fields
            // error={errors ? true : false}   
            // helperText={
            //   errors && (
            //     <span className={classes.error}>
            //       <ErrorIcon /> {errors[0]}
            //     </span>
            //   )
            // }
          />
          <FormHelperText className={classes.helperText}>
            {answer.length}/{item.characterLimit}
          </FormHelperText>
        </FormControl>
      </Paper>
    );
  } else {
    return "";
  }
};

const mapStateToProps = (state, ownProps) => {
  const answers = state.answers.byId["interview"];

  return {
    answer: getAnswer(answers, ownProps, ""),
    show: shouldQuestionShow(answers, ownProps),
  };
};

const mapDispatchToProps = {
  updateAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Text);
