import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// My Components
import Centered from "../layout/Centered";

// Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DoneIcon from "@material-ui/icons/Done";
import CasinoIcon from "@material-ui/icons/Casino";

// MUI Imports
import {
  Typography,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Container,
  CircularProgress,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// My Components
import AssessmentProgressBar from "../widgits/AssessmentProgressBar";

// Actions
import {
  updateAnswer,
  submitAnswers,
  fetchAnswers,
  sendFlash,
} from "../../actions/index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: "1 0 auto",
  },
  header: {},
  body: {
    display: "block",
    flex: "1 0 auto",
    overflow: "hidden",
    maxWidth: "96%",
    marginLeft: "auto",
    marginRight: "auto",
    // backgroundColor: 'red'
  },
  footer: {},
  list: {
    // backgroundColor: 'rgb(255, 250, 240)',
  },
  heading: {
    margin: "20px",
    marginBottom: "0px",
    textAlign: "center",
  },
  headingOuterDiv: {
    maxWidth: "900px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  button: {
    margin: "10px",
  },
  accordionContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "94%",
    marginBottom: theme.spacing(2),
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Neo = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!props.answersLoading) {
      // on load fetch completed answers
      let answerData = {
        userId: props.user._id,
        assessmentId: props.assessment._id,
      };
      props.fetchAnswers(answerData);
    }
  }, []);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // console.log(props.assessment)

  const questions = props.assessment.questions;
  const assessmentId = props.assessment._id;
  const userId = props.user._id;

  const handleSubmitAnswers = (markComplete) => {
    console.log("Submitting neo answers for scoring!");
    // e.preventDefault()
    let answerData = {
      userId: userId,
      assessmentId: assessmentId,
      totalQuestions: props.assessment.totalQuestions,
    };
    props.submitAnswers(answerData, markComplete);
  };

  const handleRandomAnswers = () => {
    let randAnswers = [];
    Object.keys(questions)?.forEach((questionNum) => {
      randAnswers[questionNum - 1] = Math.floor(Math.random() * 5); // rand 0-4
    });
    let answerData = {
      userId: userId,
      assessmentId: assessmentId,
      totalQuestions: props.assessment.totalQuestions,
      answers: randAnswers,
    };
    props.submitAnswers(answerData, true);
  };

  const instructionItems = [
    'Choose "strongly disagree" if the statement is definitely false or if you strongly disagree.',
    'Choose "disagree" is the statement is mostly false or if you disagree',
    'Choose "neutral" if the statement is about equally true or false, if you cannot decide, or if you are neutral on the statement. Try to avoid neutral answers whenever possible to yield the best results.',
    'Choose "agree" if the statement is mostly true or you agree.',
    'Choose “"strongly agree" if the statement is definitely true or if you strongly agree.',
  ];

  return (
    <div className={classes.root}>
      {/* BODY */}
      <div className={classes.body}>
        {/* HEADER */}
        <div className={classes.headingOuterDiv}>
          <div className={classes.header}>
            <Typography variant="h4" className={classes.heading}>
              {props.assessment.name}
            </Typography>
            {props.answersLoading !== "done" && (
              <Centered>
                <CircularProgress />
              </Centered>
            )}
          </div>
          <div className={classes.accordionContainer}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                style={{ padding: "0px" }}
              >
                <Typography>
                  <b>What is it?</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The NEO-PI-3 is a personality inventory that examines the “Big
                  Five” personality traits (Neuroticism, Extroversion, Openness,
                  Agreeableness, and Conscientiousness). There has been a great
                  deal of research on this personality inventory and it has
                  strong psychometrics to support its use.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                style={{ padding: "0px" }}
              >
                <Typography>
                  <b>Why is it used?</b>{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The NEO-PI-3 is helpful in Career Testing because it helps us
                  to get a deeper, clearer look at your personality and the
                  specific traits that matter for career. The NEO can help us
                  determine optimal environments for you to do your best work,
                  the types of tasks in which you would naturally
                  excel/struggle, and it can help us to make predictions about
                  potential mental barriers you might encounter when making a
                  career change. We can also gain insight into what may have
                  contributed to your dissatisfaction in previous jobs in the
                  past.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <br></br>
            <Typography>
              <b>Instructions</b>{" "}
            </Typography>
            <Typography>
              This is the longest test in the assessment battery and it takes
              about 60 minutes (240 questions). Be sure to answer every item.
              Remember, there are no right or wrong answers. Describe yourself
              honestly and state your opinions as accurately as possible.
              <ul>
                {instructionItems.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Typography>
            <br></br>
            <Typography>
              <b>Time Estimate:</b> 45-60 minutes{" "}
            </Typography>
          </div>
        </div>

        <form>
          {Object.keys(questions).map((number) => {
            let question = questions[number];
            return (
              <Question
                key={number}
                number={number}
                question={question}
                userId={userId}
                assessmentId={assessmentId}
              />
            );
          })}
        </form>
      </div>

      {/* FOOTER */}
      <div className={classes.footer}>
        <p style={{ textAlign: "center" }}>
          Adapted and reproduced by special permission of the Publisher,
          Psychological Assessment Resources, Inc. (PAR), 16204 North Florida
          Avenue, Lutz, Florida 33549, from the NEO Personality Inventory-3 by
          Paul T. Costa Jr., PhD and Robert R. McCrae, PhD, Copyright 1978,
          1985, 1989, 1991, 1992, 2010 by PAR. Further reproduction is
          prohibited without permission of PAR.
        </p>
        <div className={classes.buttonBox}>
          <Button
            variant="contained"
            component={Link}
            to="/"
            className={classes.button}
            onClick={() => handleSubmitAnswers(false)}
            startIcon={<ArrowBackIcon />}
          >
            Save & Exit
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            component={Link}
            to="/"
            onClick={() => handleSubmitAnswers(true)}
            startIcon={<DoneIcon />}
          >
            I'm Finished
          </Button>

          {
            // only show in development mode
            process.env.NODE_ENV === "development" && (
              <Button
                variant="contained"
                className={classes.button}
                component={Link}
                to="/"
                onClick={handleRandomAnswers}
                startIcon={<CasinoIcon />}
              >
                Randomize (dev)
              </Button>
            )
          }
        </div>
        <AssessmentProgressBar assessmentId={assessmentId} />
      </div>
    </div>
  );
};

let Question = (props) => {
  const { style, number, question, userId, assessmentId, answer } = props;
  const useStyles = makeStyles({
    root: {
      display: "block",
      padding: "10px",
      "& div.MuiFormGroup-root": {
        marginTop: "20px",
      },
    },
    paper: {
      padding: "20px",
    },
    question: {
      display: "flex",
    },
    options: {
      display: "flex",
      justifyContent: "center",
    },
    questionText: {
      fontSize: "1.4em",
      marginBottom: "20px",
    },
  });

  const classes = useStyles();

  const handleRadioChange = (event) => {
    const questionNum = event.target.name;
    const answer = event.target.value;
    props.updateAnswer(userId, assessmentId, questionNum, answer);
  };

  return (
    <div key={number} style={style} className={classes.root}>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <FormControl className={classes.question}>
            <FormLabel
              className={classes.questionText}
            >{`${number}. ${question?.question}`}</FormLabel>
            <RadioGroup
              row
              name={`${number}`}
              value={answer}
              onChange={handleRadioChange}
              className={classes.options}
            >
              <FormControlLabel
                labelPlacement="top"
                value="0"
                control={<Radio />}
                label="Strongly Disagree"
              />
              <FormControlLabel
                labelPlacement="top"
                value="1"
                control={<Radio />}
                label="Disagree"
              />
              <FormControlLabel
                labelPlacement="top"
                value="2"
                control={<Radio />}
                label="Neutral"
              />
              <FormControlLabel
                labelPlacement="top"
                value="3"
                control={<Radio />}
                label="Agree"
              />
              <FormControlLabel
                labelPlacement="top"
                value="4"
                control={<Radio />}
                label="Strongly Agree"
              />
            </RadioGroup>
          </FormControl>
        </Paper>
      </Container>
    </div>
  );
};

const mapState = (state, ownProps) => {
  const answerIndex = ownProps.number - 1;
  const answers = state.answers.byId["neo"];
  return {
    answer: answers ? answers.answers[answerIndex] : "",
  };
};
Question = connect(mapState, { updateAnswer })(Question);

const mapDispatchToProps = {
  updateAnswer,
  submitAnswers,
  fetchAnswers,
  sendFlash,
};

const mapStateToProps = (state) => {
  const assessmentId = "neo";
  const answers = state.answers.byId[assessmentId];
  return {
    user: state.user,
    assessment: state.assessments.byId[assessmentId],
    answersLoading: answers ? answers.loading : false,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Neo);
