import produce from "immer";

import {
  LOAD_ALL_USER_DATA,
  SET_ALL_USERS_LOADING,
  SET_USER_RESULTS,
  UPDATE_INTERVIEW_QUESTIONS,
} from "../actions/index";

const initialState = {
  userList: {},
  userDetail: {},
  interviewQuestionVersions: [],
  records: [],
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_ALL_USERS_LOADING:
        draft.userList.loading = true;
        return draft;

      case LOAD_ALL_USER_DATA:
        draft.userList.loading = false;
        draft.userList.users = [...action.data];
        return draft;

      case SET_USER_RESULTS:
        const { userId, data } = action;
        draft.userDetail[userId] = { ...data };
        return draft;

      case UPDATE_INTERVIEW_QUESTIONS:
        const { questionData } = action;
        // only add if it doesnt already exist

        // check if version already exists
        const alreadyExists = draft.interviewQuestionVersions.some(
          (version) => version.version === questionData.version
        );

        if (!alreadyExists) {
          draft.interviewQuestionVersions.push(questionData);
        }

        return draft;

      default:
        break;
    }
  });
