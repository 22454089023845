import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

// MUI Imports
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  TextField,
  Paper,
} from "@material-ui/core";

// My Imports
import { shouldQuestionShow, getAnswer } from "./helpers";

// Actions
import { updateAnswer } from "../../../actions/index";
import HoverDetails from "../HoverDetails";

const useStyles = makeStyles({
  text: {
    width: "300px",
    "& input": {
      paddingTop: "12px",
    },
  },
});

const Other = (props) => {
  const classes = useStyles();
  const { selected, answer, number } = props;

  if (selected) {
    return (
      <TextField
        id="1"
        name={`${number}`}
        label={false}
        fullWidth={false}
        variant="filled"
        className={classes.text}
        placeholder="Please Elaborate"
        value={answer.other}
        onChange={props.handleTextChange}
      />
    );
  } else {
    return "Other";
  }
};

let SelectMultiple = (props) => {
  const classes = useStyles();

  const { number, style, item, userId, assessmentId, answer } = props;

  const handleCheckboxChange = (choice, event) => {
    const questionNum = event.target.name;
    let value;
    if (choice !== "other") {
      value = event.target.checked;
    } else {
      value = event.target.checked ? "" : false;
    }
    const answer = {
      choice: choice,
      value: value,
    };
    props.updateAnswer(userId, assessmentId, questionNum, answer, "checkbox");
  };

  const handleTextChange = (event) => {
    const questionNum = event.target.name;
    const answer = {
      choice: "other",
      value: event.target.value,
    };
    props.updateAnswer(userId, assessmentId, questionNum, answer, "checkbox");
  };

  if (props.show) {
    return (
      <Paper style={style} className={props.questionStyles}>
        <FormControl>
          <FormLabel>
            {item.question} <HoverDetails description={item.description} />
          </FormLabel>
          <FormGroup>
            {/* Question Choices */}
            {Object.keys(item.choices).map((option) => {
              let display = option === "other" ? "Other" : item.choices[option];
              let value = answer[option];
              let checked;
              if (value || value === "") {
                if (value === false) {
                  checked = false;
                } else {
                  checked = true;
                }
              } else {
                checked = false;
              }
              return (
                <FormControlLabel
                  key={option}
                  label={
                    option !== "other" ? (
                      display
                    ) : (
                      <Other
                        answer={answer}
                        handleTextChange={handleTextChange}
                        selected={checked}
                        number={number}
                      />
                    )
                  }
                  control={
                    <Checkbox
                      name={`${number}`}
                      checked={checked}
                      onChange={(event) => handleCheckboxChange(option, event)}
                    />
                  }
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Paper>
    );
  } else return "";
};

const mapStateToProps = (state, ownProps) => {
  const answers = state.answers.byId["interview"];

  return {
    answer: getAnswer(answers, ownProps, []),
    show: shouldQuestionShow(answers, ownProps),
  };
};

const mapDispatchToProps = {
  updateAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectMultiple);
