export const themeDescriptions = {
  artistic: {
    name: 'Artistic',
    code: 'A',
    interests: 'Self-expression, art appreciation, communication, culture',
    workActivities: 'Composing music, performing, writing, creating visual art',
    potentialSkills: 'Creativity, musical ability, artistic expression',
    values: 'Beauty, originality, independence, imagination'
  },
  realistic: {
    name: 'Realistic',
    code: 'R',
    interests: 'Machines, computer networks, athletics, working outdoors',
    workActivities: 'Operating equipment, using tools, building, repairing, providing security',
    potentialSkills: 'Mechanical ingenuity and dexterity, physical coordination',
    values: 'Tradition, practicality, common sense'
  },
  investigative: {
    name: 'Investigative',
    code: 'I',
    interests: 'Science, medicine, mathematics, research',
    workActivities: 'Performing lab work, solving abstract problems, conducting research',
    potentialSkills: 'Mathematical ability, researching, writing, analyzing',
    values: 'Independence, curiosity, learning'
  },
  enterprising: {
    name: 'Enterprising',
    code: 'E',
    interests: 'Business, politics, leadership, entrepreneurship',
    workActivities: 'Selling, managing, persuading, marketing',
    potentialSkills: 'Verbal ability, ability to motivate and direct others',
    values: 'Risk taking, status, competition, influence'
  },
  social: {
    name: 'Social',
    code: 'S',
    interests: 'People, teamwork, helping, community service',
    workActivities: 'Teaching, caring for people, counseling, training employees',
    potentialSkills: 'People skills, verbal ability, listening, showing understanding',
    values: 'Cooperation, generosity, service to others'
  },
  conventional: {
    name: 'Conventional',
    code: 'C',
    interests: 'Organization, data management, accounting, investing, information systems',
    workActivities: 'Setting up procedures and systems, organizing, keeping records, developing computer applications',
    potentialSkills: 'Ability to work with numbers, data analysis, finances, attention to detail',
    values: 'Accuracy, stability, efficiency'
  }
}
