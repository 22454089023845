import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import errorReducer from "./errorReducer";
import flashReducer from "./flashReducer";
import assessmentReducer from "./assessmentReducer";
import answerReducer from "./answerReducer";
import resultsReducer from "./resultsReducer";
import adminReducer from "./adminReducer";
import plansReducer from "./plansReducer";
import recordReducer from "./recordsReducer";

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  assessments: assessmentReducer,
  answers: answerReducer,
  results: resultsReducer,
  admin: adminReducer,
  plans: plansReducer,
  records: recordReducer,
  flash: flashReducer,
  errors: errorReducer,
});
