import produce from 'immer';

import {
  SET_USER_DATA,
  EXPUNGE_USER_DATA,
  SET_USER_LOADING,
  SET_USER_CONSENT,
  SET_USER_SCHEDULE
} from "../actions/index";

const initialState = {};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {

      case SET_USER_DATA:
        let data = action.data
        let plan = action.data.plans[0]
        const { assessmentData, ...userData } = { ...data } // remove assessmentData from data
        const { assessments, plans, ...justPlan } = { ...plan }; // remove assessments, plans from plan

        draft = { ...userData }
        // draft.plan = { ...justPlan }
        draft.loading = false
        return draft

      case EXPUNGE_USER_DATA:
        draft = {}
        return draft

      case SET_USER_LOADING:
        draft.loading = true
        return draft

      case SET_USER_CONSENT:
        draft.consent = true
        return draft

      case SET_USER_SCHEDULE: {
        const { meetingType, eventId, userId, startTime } = action

        if (!draft.appointments) {
          draft.appointments = {}
        }

        if (meetingType === 'interview') {
          draft.interviewScheduled = true
          draft.appointments.interview = {
            scheduled: true,
            userId: userId,
            eventId: eventId,
            startTime: startTime
          }
        } else if (meetingType === 'results') {
          draft.resultsScheduled = true
          draft.appointments.results = {
            scheduled: true,
            userId: userId,
            eventId: eventId,
            startTime: startTime
          }
        }

        return draft
      }

      default:
        break;
    }
  })
