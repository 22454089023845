import React, { useEffect } from "react";
import { connect } from "react-redux";
import isEmpty from "is-empty";

// My Components
import Results from "../main/Results";

// Actions
import { fetchDemoUserData } from '../../actions/index';

const DemoResults = (props) => {
  // console.log('props:', props)

  // Load demo user data
  useEffect(() => {
    if (isEmpty(props.user)) {
      props.fetchDemoUserData() // fetch user data via api and store in redux
    }
  }, [props.user]);

  return (
    <Results { ...props } />
  )
}

const mapDispatchToProps = {
  fetchDemoUserData
};

const mapStateToProps = state => {
  return ({
    user: state.user,
    assessments: state.assessments,
    results: state.results,
    careerValueAnswers: state.answers.byId['career-values']
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoResults);
