import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "is-empty";

// const auth = { isAuthenticated: true };
// const userDataLoaded = true;
const PrivateRoute = ({
  component: Component,
  auth,
  userDataLoaded,
  redirect,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        // redirect to / if user is not authenticated or data isn't loaded (user hits refresh)
        auth.isAuthenticated === true && userDataLoaded ? (
          <>
            {console.log(auth.isAuthenticated)}
            <Component {...props} />
          </>
        ) : redirect === true && !userDataLoaded ? (
          // <Redirect to="/" />
          <div>Loading...</div>
        ) : (
          <div>
            {auth.isAuthenticated && <Component {...props} />}
          </div>
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const loaded = !isEmpty(state.assessments);
  return {
    auth: state.auth,
    userDataLoaded: loaded,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
