import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { globalStyles } from './constants/globalStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// MUI Imports
import {
  Container, Typography, Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';

// Data Import
// import { faqs } from '../../constants/faq';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useGlobalStyles = makeStyles({
  ...globalStyles
});

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgb(230, 238, 240)',
  },
  heading: {
    fontSize: '1.3em',
    // fontWeight: 500
  }
});

const Faq = (props) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const { data } = props
  const faqs = data.contentCollection.items

  return(
    <div className={`${classes.root} ${globalClasses.section}`}>
      <Container maxWidth='md'>
        <Typography variant='h3' align='center' gutterBottom>
          {/* Frequently Asked Questions */}
          {data.sectionTitle}
        </Typography>

        {/* {copy.content.map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>{faq.fields.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component='div'>
                {documentToReactComponents(faq.fields.answer)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))} */}

        {faqs.map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component='div'>
                {documentToReactComponents(faq.answer.json)}
                {/* test */}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}

      </Container>
    </div>
  )
}

export default Faq;
