import React, { useState } from "react";
// import "./styles.css";
import BackgroundImage from "../../../images/graphics/admin-record-bg.png";
import Logo from "../../../images/logo_black.svg";
import { makeStyles } from "@material-ui/core/styles";
import WestIcon from "@mui/icons-material/West";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// MUI Imports
import {
  Typography,
  CircularProgress,
  Container,
  List,
  LinearProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@material-ui/core";

import ConfirmModal from "./ConfirmModal";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

// Styles
const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "grid",
    gridTemplateColumns: " repeat(3, minmax(0, 1fr))",
    width: "100%",
  },

  heading: {
    fontSize: "46px",
    fontWeight: "500",
    color: "#7D80C6",
    textAlign: "center",
  },
  label: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#383050",
    marginBottom: "6px",
    marginTop: "0px",
    textTransform: "uppercase",
  },
  inputGrid: {
    display: "grid",
    gridTemplateColumns: " repeat(3, minmax(0, 1fr))",
    fontSize: "0.875rem",
    paddingTop: "75px",
    gap: "24px",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
  },
  inputfield: {
    outline: "none",
    width: "100%",
    fontSize: " 14px",
    fontWeight: "400",
    fontFamily: "Lato",
    borderRadius: " 4px",
    backgroundColor: " white",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
    border: "1px solid #383050",
    "&::placeholder": {
      color: "#0000004D",
    },
  },

  logo: {
    width: "112px",
    marginLeft: "6px",
  },
  backIcon: {
    fontSize: "2.3rem !important",
    color: "#7D80C6 !important",
  },
  subBtn: {
    paddingLeft: "60px",
    paddingRight: "60px",
    paddingTop: "14px",
    paddingBottom: "14px",
    fontWeight: " 600",
    fontSize: "16px",
    fontFamily: "Roboto",
    backgroundColor: "#7D80C6",
    color: "white",
    borderRadius: "4px",
    border: "none",
    marginTop: "25px",
    marginBottom: "30px",
    cursor: "pointer",
  },
}));

export default function AddRecords() {
  const classes = useStyles();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [record, setRecord] = useState({
    jobTitle: "",
    familyType: "",
    secondaryFamily: "",
    MBTI_1: "", //introvert/extrovert
    MBTI_2: "", //motivation
    MBTI_3: "", //judging/perceiving
    SII_1: "",
    SII_2: "",
    SII_3: "",
    salary: "",
    stress_level: "",
    min_edu: "",
    description: "",
  });

  const handleConfirmClose = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const handleInput = (event) => {
    setErrorMessage("");
    const { name, value } = event.target;
    setRecord((prevRecord) => ({
      ...prevRecord,
      // [name]: name === "description" ? value : value.trim(), // if description don't trim
      [name]: value,
    }));
  };

  const checkForEmptyRecord = (finalRecord) => {
    const noEmptyRecord = Object.keys(finalRecord).every(
      (key) => finalRecord[key] !== ""
    );
    return noEmptyRecord;
  };

  const submitHandler = () => {
    // Create a new object with all fields trimmed
    const trimmedRecord = Object.keys(record).reduce((acc, key) => {
      // acc[key] = key === "description" ? record[key] : record[key].trim();
      acc[key] = record[key].trim();
      return acc;
    }, {});

    setRecord(trimmedRecord);

    // console.log(checkForEmptyRecord(trimmedRecord));
    if (!checkForEmptyRecord(trimmedRecord)) {
      setErrorMessage("All fields are required");
      return;
    }
    setOpenConfirmModal(true);
  };

  return (
    <Box
      sx={{
        paddingY: "30px",
        paddingX: "24px",
        backgroundImage: `url(${BackgroundImage})`,
        backgroundColor: "white",
        backgroundSize: "cover",
        objectFit: "cover",
        minHeight: "100vh",
        fontFamily: "Bebas Neue",
      }}
    >
      <div>
        <div className={classes.headerContainer}>
          {/* <img src={Logo} className={classes.logo} alt="Logo" /> */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link to="/admin/search-record">
              <WestIcon fontSize="inherit" className={classes.backIcon} />
            </Link>
          </div>
          <div className={classes.heading}>ADD A RECORD</div>
          <div />
        </div>
        <div className={classes.inputGrid}>
          <div>
            <h3 className={classes.label}>Job Title</h3>
            <input
              className={classes.inputfield}
              name="jobTitle"
              placeholder="Enter"
              value={record.jobTitle}
              onChange={handleInput}
            />
          </div>
          <div>
            {/* <h3 className={classes.label}>Family Type</h3> */}
            <h3 className={classes.label}>Job Family</h3>
            <input
              className={classes.inputfield}
              name="familyType"
              placeholder="Enter"
              value={record.familyType}
              onChange={handleInput}
            />
          </div>
          <div>
            {/* <h3 className={classes.label}>Secondary Family</h3> */}
            <h3 className={classes.label}>Secondary Type</h3>
            <input
              className={classes.inputfield}
              name="secondaryFamily"
              placeholder="Enter"
              value={record.secondaryFamily}
              onChange={handleInput}
            />
          </div>
          {/* MBTI code fields */}
          <div>
            <h3 className={classes.label}>Introvert/Extrovert</h3>
            <input
              className={classes.inputfield}
              name="MBTI_1"
              placeholder="Enter"
              value={record.MBTI_1}
              onChange={handleInput}
            />
          </div>
          <div>
            <h3 className={classes.label}>Motivation</h3>
            <input
              className={classes.inputfield}
              name="MBTI_2"
              placeholder="Enter"
              value={record.MBTI_2}
              onChange={handleInput}
            />
          </div>
          <div>
            <h3 className={classes.label}>Judging/Percieving</h3>
            <input
              className={classes.inputfield}
              name="MBTI_3"
              placeholder="Enter"
              value={record.MBTI_3}
              onChange={handleInput}
            />
          </div>
          {/* SII code fields */}
          <div>
            <h3 className={classes.label}>Interest 1</h3>
            <input
              className={classes.inputfield}
              name="SII_1"
              placeholder="Enter"
              value={record.SII_1}
              onChange={handleInput}
            />
          </div>
          <div>
            <h3 className={classes.label}>Interest 2</h3>
            <input
              className={classes.inputfield}
              name="SII_2"
              placeholder="Enter"
              value={record.SII_2}
              onChange={handleInput}
            />
          </div>
          <div>
            <h3 className={classes.label}>Interest 3</h3>
            <input
              className={classes.inputfield}
              name="SII_3"
              placeholder="Enter"
              value={record.SII_3}
              onChange={handleInput}
            />
          </div>
          <div>
            <h3 className={classes.label}>Salary</h3>
            <input
              className={`${classes.inputfield} hidden-number-input`}
              name="salary"
              placeholder="Enter"
              type="number"
              value={record.salary}
              onChange={handleInput}
            />
          </div>
          <div>
            <h3 className={classes.label}>Stress Level</h3>
            <input
              className={classes.inputfield}
              name="stress_level"
              placeholder="Enter"
              value={record.stress_level}
              onChange={handleInput}
            />
          </div>
          <div>
            <h3 className={classes.label}>Minimum Education</h3>
            <input
              className={classes.inputfield}
              name="min_edu"
              placeholder="Enter"
              value={record.min_edu}
              onChange={handleInput}
            />
          </div>
        </div>
        <div style={{ marginTop: "24px" }}>
          <h3 className={classes.label}>Description</h3>
          <textarea
            className={classes.inputfield}
            name="description"
            placeholder="Enter"
            rows={12}
            value={record.description}
            onChange={handleInput}
          />
        </div>
        {errorMessage && (
          <div
            style={{
              color: "red",
              fontFamily: "Roboto",
              fontSize: "14px",
              paddingTop: "8px",
            }}
          >
            {errorMessage}
          </div>
        )}
        <button className={classes.subBtn} onClick={submitHandler}>
          Submit
        </button>
        {openConfirmModal && (
          <ConfirmModal
            handleConfirmClose={handleConfirmClose}
            record={record}
          />
        )}
      </div>
    </Box>
  );
}
