import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import pssLookup from "../../constants/pssLookup.json";

import BisResults from "./BisResults";
import OsResults from "./OsResults";
import { gotBoundaries } from "../../constants/results/GotInterpretiveBoundaries";

// My Imports
import PssVis from '../widgits/PssVis';
import PssVisHeader from '../widgits/PssVisHeader';
import { colorStyles } from '../../constants/colorStyles';
import { themeDescriptions } from '../../constants/themeDescriptions';
import Centered from "../layout/Centered";
import HappyBox from "../layout/HappyBox";

// GQL Query Imports
import { GET_SII_RESULTS } from './constants/queries';

// MUI Imports
import {
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, LinearProgress, Chip, CircularProgress, Accordion, AccordionSummary,
  AccordionDetails
} from '@material-ui/core';

// My Imports
import CustomTooltip from "../layout/CustomTooltip";

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
  root: {
  },
  paper: {
    padding: '20px',
    marginBottom: '20px'
  },
  subHeading: {
    marginTop: '30px',
    margin: '20px 0'
  },
  bar: {
    flex: '1 0 auto',
    height: '10px'
  },
  bisContainer: {
    maxHeight: '600px'
  },
  osContainer: {
    maxHeight: '600px'
  },
  pssRight: {
    paddingLeft: 0
  },
  gotContainer: {
    padding: '20px'
  },
  themeDescriptions: {
    marginBottom: '20px'
  },
  pssVis: {
    padding: '2px',
    width: '35%',
    '& svg': {
      // minWidth: '400px' // svg visualization width
    }
  },
  topTheme: {
    marginBottom: '5px'
  },
  ...colorStyles // styles for the colors that aren't primary or secondary
});


const SiiResults = (props) => {
  const classes = useStyles();
  const { results } = props

  // Load Contentful Data
  const { loading, error, data } = useQuery(GET_SII_RESULTS);

  const gender = results.SII.gender || false

  const siiData = results.SII.siiMeasures
  const pdfData = results.SII.pdfData

  // sort values and list em
  const GOT = () => {
    const got = siiData.GOT // old data (bad)
    const gotPdf = pdfData?.got

    const topThreeThemes = Object.keys(gotPdf).sort((a, b) => {
      return gotPdf[a] - got[b]
    }).slice(0, 3)

    // For rendering the right-most Chip text in GOT table
    const calcBoundry = (theme, gender, score) => {
      if (gender) {
        if (score <= gotBoundaries[theme]["Very Little"][gender]) return "Very Little"
        else if (score <= gotBoundaries[theme]["Little"][gender]) return "Little"
        else if (score <= gotBoundaries[theme]["Average"][gender]) return "Average"
        else if (score <= gotBoundaries[theme]["High"][gender]) return "High"
        else return "Very High"
      } else return ""
    }

    if (gotPdf) {
      const themeDetails = data.siiResults.themes.items

      return (
        <>
          <HappyBox id='got'>
            <Typography variant='h5' gutterBottom>
              General Occupational Themes (GOT)
            </Typography>

            <div style={{
              display: 'flex',
              justifyContent: 'space-around',
              margin: '20px'
            }}>
              <div>
                <Typography variant='h6'>
                  <b>Your Top Themes</b>
                </Typography>
                <ol>
                  {/* themeDetails.find(theme => theme.name === key) */}
                  {topThreeThemes.map((theme, index) => {
                    const interpretation = themeDetails
                      .find(item => item.name === theme).interpretation

                    return (
                      <CustomTooltip key={index} arrow placement='top'
                        title={interpretation}
                      >
                        <li className={classes.topTheme}>
                          <Chip
                            label={theme} size='small'
                            className={classes[theme.toLowerCase()]}
                          />
                        </li>

                      </CustomTooltip>
                    )
                  })}
                </ol>
              </div>
              <div>
                <Typography variant='h6' gutterBottom>
                  <b>Your Theme Code:</b>
                </Typography>
                <Typography variant='h3'>
                  {topThreeThemes.map(theme => theme.charAt(0))}
                </Typography>
              </div>
            </div>

            <TableContainer style={{marginBottom: '30px'}}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Theme</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Standard Score & Interest Level</TableCell>
                    <TableCell align="right">Standard Score</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(gotPdf).map((key, index) => {
                    const score = gotPdf[key]
                    const detail = themeDetails.find(theme => theme.name === key)

                    const description = detail.description
                    const interpretation = detail.interpretation

                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="theme">
                          <CustomTooltip arrow placement='top'
                            title={description || key}
                          >
                            <Chip
                              label={key} size='small'
                              className={classes[key.toLowerCase()]}
                            />
                          </CustomTooltip>
                        </TableCell>
                        <TableCell>
                          {key.charAt(0)}
                        </TableCell>
                        <TableCell align="right">
                          <LinearProgress variant="determinate" value={score} className={classes.bar} />
                        </TableCell>
                        <TableCell align="right">{score}</TableCell>
                        <TableCell align="right">
                          {gender &&
                            <Chip label={calcBoundry(key, gender, score)} size='small' />
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'>
                  Theme Descriptions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ThemeDescriptions />
              </AccordionDetails>
            </Accordion>
          </HappyBox>
        </>
      )
    } else {
      return (
        <div className={classes.gotContainer}>
          <Typography variant='h5' gutterBottom>
            General Occupational Themes (GOT)
          </Typography>
          <ThemeDescriptions />
          <ol>
            {Object.keys(got)
              .sort((a, b) => {
                return got[a].value > got[b].value ? 1 : -1
              })
              .map((key, index) => {
                const theme = got[key]
                return (
                  <li key={index}>{theme.name}</li>
                )
            })}
          </ol>
        </div>
      )
    }
  }

  const ThemeDescriptions = () => {
    const gotPdf = pdfData?.got
    if (pdfData && pdfData.got) {
      return(
        <TableContainer className={classes.themeDescriptions}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Theme</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Interests</TableCell>
                <TableCell>Work Activities</TableCell>
                <TableCell>Potential Skills</TableCell>
                <TableCell>Values</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(gotPdf).map((key, index) => {
                const theme = themeDescriptions[key.toLowerCase()]
                return (
                  <TableRow key={index}>
                    <TableCell component="th">
                      <Chip
                        label={theme.name} size='small'
                        className={classes[key.toLowerCase()]}
                      />
                    </TableCell>
                    <TableCell>{theme.code}</TableCell>
                    <TableCell>{theme.interests}</TableCell>
                    <TableCell>{theme.workActivities}</TableCell>
                    <TableCell>{theme.potentialSkills}</TableCell>
                    <TableCell>{theme.values}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )
    } else return ''
  }

  const PSS = () => {
    const pss = siiData.PSS
    return (
      <HappyBox id='pss'>
        <Typography variant='h5' gutterBottom>
          Personal Style Scales (PSS)
        </Typography>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Personal Style Scale</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="center" className={classes.pssVis}>
                  <PssVisHeader />
                </TableCell>
                <TableCell></TableCell>
                <TableCell align="right" className={classes.pssRight}>Standard Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(pss).map((key, index) => {
                const style = pss[key]
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="style">
                      {style.name}
                    </TableCell>
                    <TableCell align="right">{pssLookup[style.name][0]}</TableCell>
                    <TableCell align="right" className={classes.pssVis}>
                      {/* <LinearProgress variant="determinate" value={style.value} className={classes.bar} /> */}
                      <PssVis value={style.value} />
                    </TableCell>
                    <TableCell>{pssLookup[style.name][1]}</TableCell>
                    <TableCell align="right">{style.value}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </HappyBox>
    )
  }

  const SCI = () => {
    const sciData = siiData.SCI
    const sciPdf = pdfData?.sci
    const { SCI_MISSING_COUNT, ...sci } = sciData

    const skillAreas = {
      Realistic: 'Outdoor work, construction, repair',
      Artistic: 'Creative expression, music, design',
      Investigative: 'Research, math, science',
      Enterprising: 'Sales, speaking, management',
      Social: 'Education, counseling, social service',
      Conventional: 'Finance, computers, organization'
    }

    if (sciPdf) {
      return(
        <HappyBox id='sci'>
          <Typography variant='h5' gutterBottom>
            Skills Confidence Inventory (SCI)
          </Typography>

          <TableContainer>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Theme</TableCell>
                  <TableCell>Confidence Score & Level</TableCell>
                  <TableCell>Score (1-5)</TableCell>
                  <TableCell>Typical Skill Areas</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(sciPdf).map((key, index) => {
                  const score = sciPdf[key]
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="theme">
                        <Chip
                          label={key} size='small'
                          // onClick={() => toggleFilter(row.theme)}
                          className={classes[key.toLowerCase()]}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <LinearProgress variant="determinate" value={score * 20} className={classes.bar} />
                      </TableCell>
                      <TableCell>{score}</TableCell>
                      <TableCell>{skillAreas[key]}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </HappyBox>
      )
    } else {
      return (
        <Paper style={{padding: '10px'}}>
          <ol>
            {Object.keys(sci)
              .sort((a, b) => {
                return sci[a].value > sci[b].value ? 1 : -1
              })
              .map((key, index) => {
              const theme = sci[key]
              return (
                <li key={index}>
                  {theme.name.substring(0, theme.name.length - 4)}
                </li>
              )
            })}
          </ol>
        </Paper>
      )
    }
  }

  if (loading) {
    return(
      <Centered>
        <CircularProgress />
      </Centered>
    )
  } else {
    const assessment = data.siiResults.assessment
    const themeData = data.siiResults.themes.items // contentful data
    // console.log(themeData)

    return(
      <>
        <HappyBox>
          <Typography variant='h4' gutterBottom>
            Strong Interest Inventory (SII)
          </Typography>

          <Typography gutterBottom component='div'>
            { documentToReactComponents(assessment.longDescription.json) }
          </Typography>
        </HappyBox>

        <GOT />

        <BisResults results={siiData.BIS} />

        <OsResults results={siiData.OS} />

        <PSS />

        <SCI />
      </>
    )
  }
}

export default SiiResults
