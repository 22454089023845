export const colors = {
  orange: "#fbb348",
  blue: "#3791da",
  // wanderlustBlue: "#40a5f6", // Wanderlust Blue
  pink: "#f640e9",
  red: "#f44336",
  green: "#3be489",
  white: "#FFFFFF",
  offWhite: "#F4F6F8" // Bkg color
};
