import { UPDATE_FLASH, CLEAR_FLASH } from "../actions/index";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {

    case UPDATE_FLASH:
      return {
        text: action.message,
        type: action.messageType
      };

    case CLEAR_FLASH:
      return {};

    default:
      return state;
  }
}
