import { gql } from '@apollo/client';

export const GET_STEP = gql`
  query GetStep {
    step(id: "17DhsPfiOvbohzJp4kKtXY") {
      name
      id
      description
    }
  }
`

export const GET_LANDING_PAGE_DATA = gql`
  query GetLandingPageData {
    # Fetches One Module (Landing Page)
    module(id: "xdcsCmCJTMp3JAJyD1fQ2") {
      name
      contentCollection(limit: 4) {
        total # total number of items in collection
        items {
          # Sections
          ... on Section {
            name
            id
            sectionTitle
            contentCollection(limit: 20) {
              total
              items {
                ... on Step {
                  name
                  id
                  description
                }
                ... on Testimonial {
                  name
                  city
                  quote
                  image {
                    url
                  }
                }
                ... on PlanFeature {
                  name
                  shortDescription
                  info {
                    json
                  }
                  free
                  basic
                  essential
                  premium
                }
                ... on Faq {
                  question
                  answer {
                    json
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
