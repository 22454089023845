import { gql } from '@apollo/client';

export const GET_INITIAL_DATA = gql`
  query GetInitialData {

    # Instructions
    instructions: instructionCollection {
      items {
        id
        name
        instructions { json }
      }
    }

    # Assessment Data
    assessments: assessmentCollection {
      items {
        name
        id
        code
        shortDescription
        longDescription { json }
        instructions { json }
        timeToComplete
      }
    }
  }
`
