import React, { useEffect, useState } from "react";
import "./styles.css";
import SuccessModal from "../SuccessModal";
// redux imports
import { connect } from "react-redux";
import { addRecord, cleanAddRecord } from "../../../../actions";

// MUI Imports
import { CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const ConfirmModal = ({ handleConfirmClose, record, ...props }) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const { loading, successMessage } = props;

  console.log(props);
  const handleSuccessClose = () => {
    try {
      setOpenSuccessModal(!openSuccessModal);
      handleConfirmClose();
    } catch (error) {
      console.error("Error navigating:", error);
    }
  };

  const handleSubmit = () => {
    props.addRecord(record);
  };

  useEffect(() => {
    if (successMessage && successMessage === "record added successfully") {
      setOpenSuccessModal(true);
    } else if (
      successMessage &&
      successMessage === "Request failed with status code 404"
    ) {
      setErrorMessage("All fields should be filled");
    } else if (successMessage) {
      setErrorMessage("Record is not added");
    }
    return () => {
      props.cleanAddRecord();
    };
  }, [successMessage]);
  return (
    <div className="modalMain">
      <div className="modalTrans" onClick={handleConfirmClose}></div>
      <div className="modalcontainer no-scrollbar ">
        <table>
          <tr>
            <th className="confirm-modal-th">Job Title</th>
            <th className="confirm-modal-th">Family/Type</th>
            <th className="confirm-modal-th">Secondary Family</th>
            <th className="confirm-modal-th">Introvert/Extrovert</th>
            <th className="confirm-modal-th">Motivation</th>
            <th className="confirm-modal-th">Judging/Percieving</th>
            <th className="confirm-modal-th">Interest 1</th>
            <th className="confirm-modal-th">Interest 2</th>
            <th className="confirm-modal-th">Interest 3</th>
            <th className="confirm-modal-th">Salary</th>
            <th className="confirm-modal-th">Stress Level</th>
            <th className="confirm-modal-th">Minimum Education</th>
            <th className="confirm-modal-th">Description</th>
          </tr>
          <tr>
            <td className="tablecell">{record.jobTitle}</td>
            <td className="tablecell">{record.familyType}</td>
            <td className="tablecell">{record.secondaryFamily}</td>
            <td className="tablecell">{record.MBTI_1}</td>
            <td className="tablecell">{record.MBTI_2}</td>
            <td className="tablecell">{record.MBTI_3}</td>
            <td className="tablecell">{record.SII_1}</td>
            <td className="tablecell">{record.SII_2}</td>
            <td className="tablecell">{record.SII_3}</td>
            <td className="tablecell">{record.salary}</td>
            <td className="tablecell">{record.stress_level}</td>
            <td className="tablecell">{record.min_edu}</td>
            <td className="des">{record.description}</td>
          </tr>
        </table>
        {/* {successMessage && <p>{successMessage}</p>} */}
      </div>
      <div className="btnsContainer">
        <button className="subBtn" onClick={handleSubmit}>
          {loading ? (
            <CircularProgress style={{ color: "white" }} size={15} />
          ) : (
            "Confirm"
          )}
        </button>
        <button className="cancelBtn" onClick={handleConfirmClose}>
          Cancel
        </button>
      </div>
      {openSuccessModal && (
        <SuccessModal
          openSuccessModal={openSuccessModal}
          handleSuccessClose={handleSuccessClose}
        />
      )}

      {errorMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={errorMessage !== ""}
          autoHideDuration={5000}
          onClose={() => setErrorMessage("")}
        >
          {/* error, warning, info, success */}
          <Alert elevation={6} variant="filled" severity={"error"}>
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  addRecord,
  cleanAddRecord,
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.records.successMessage,
    loading: state.records.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
