import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion"

const useStyles = makeStyles({
  root: {},
  bkg: {
    fill: 'rgba(0, 0, 0, 0.05)'
  },
  bar: {
    fill: 'rgba(77, 110, 227, 1)'
  },
  dividerLine: {
    strokeWidth: '0.1',
    stroke: 'rgb(255, 255, 255, 0.5)',
    // strokeDasharray: '0.5'
  }
});

// For indicating 1-100 values
// can cut off w/ min & max props
const Bar = (props) => {
  const { value, min, max, height } = props

  const lines = [ 10, 15, 20, 30, 40, 50, 55, 60 ]

  // default props?

  const classes = useStyles();

  return(
    <svg viewBox={`${min} 0 ${max-min} ${height}`} xmlns="http://www.w3.org/2000/svg" className={classes.root}>

      {/* BKG Rect */}
      <rect width="100" height={height} className={classes.bkg} />

      {/* Value Rect */}
      <motion.rect height={height} className={classes.bar}
        animate={{width: value}}
        initial={{width: 0}}
      />

      {lines.map((dist, index) => (
        <line key={index} x1={dist} y1="0" x2={dist} y2={height} className={classes.dividerLine} />
      ))}

    </svg>
  )
}

export default Bar;
