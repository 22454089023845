import React from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import { globalStyles } from './constants/globalStyles';
import { Mixpanel } from "../../utils/mixpanel";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useQuery } from "@apollo/client";

// Data Imports
import { colors } from "../../constants/colors";

// MUI Imports
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";

// My Imports
import Centered from "../layout/Centered";

// GQL Query Imports
import { GET_GRID_DATA } from "../../constants/gql-queries/grid";

// Icons
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import VideocamIcon from "@material-ui/icons/Videocam";
import CreateIcon from "@material-ui/icons/Create";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    padding: "20px",
    fontSize: "1em",
    "& p": {
      margin: 0,
    },
  },
  arrow: {
    // color: theme.palette.common.black,
  },
}))(Tooltip);

// const useGlobalStyles = makeStyles({
//   ...globalStyles
// });

const useStyles = makeStyles({
  // root: {
  //   backgroundColor: 'rgb(255, 255, 255)',
  // },
  // container: {
  //   display: 'flex',
  //   marginBottom: '20px'
  // },
  tableContainer: {
    marginBottom: "50px",
  },
  table: {
    "& th": {
      fontSize: "1.2em",
      "& svg": {
        fontSize: "1.2em",
        marginLeft: "5px",
        marginBottom: "2px",
        color: "rgba(0, 0, 0, 0.2)",
        verticalAlign: "middle",
        transition: "color 0.2s ease",
        "&:hover": {
          color: "rgba(0, 0, 0, 0.4)",
        },
      },
      "@media (max-width: 780px)": {
        fontSize: "1em",
      },
    },
  },
  green: {
    color: colors.green,
    fontSize: "2em",
  },
  icon: {
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: "2em",
  },
  checkIcon: {
    color: colors.green,
    fontSize: "2em",
  },
  xIcon: {
    color: colors.red,
    fontSize: "2em",
  },
  button: {
    "@media (max-width: 780px)": {
      fontSize: "0.8em",
    },
  },
  help: {
    display: "flex",
    justifyContent: "center",
    "& div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  customWidth: {
    maxWidth: 200,
    textAlign: "center",
  },
  featureColumn: {
    width: "50%",
  },
});

const Grid = (props) => {
  // const globalClasses = useGlobalStyles();
  const classes = useStyles();

  // Load Contentful Data
  const { loading, error, data } = useQuery(GET_GRID_DATA);
  console.log("loading:", loading, "data:", data);
  // console.log('TEST', data.gridData.planFeatures.items)
  // const gridData = data.gridData

  const { plans, showContact, actionButtonText, showActionButtons } = props;
  // const planFeatures = gridData.contentCollection.items
  // const planFeatures = loading ? false : data.gridData.planFeatures.items

  // console.log(gridData)

  // Adds commas to numbers
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const featureIcon = (included) => {
    if (included === "included") {
      return <CheckCircleIcon className={classes.checkIcon} />;
    } else if (included) {
      if (included === "video") {
        return (
          <Tooltip
            placement="top"
            arrow
            classes={{ tooltip: classes.customWidth }}
            title="Live video chat with a Ph.D. Level Career Counselor (45 min)"
          >
            <VideocamIcon className={classes.checkIcon} />
          </Tooltip>
        );
      } else if (included === "assessment") {
        return (
          <Tooltip placement="top" arrow title="Written Format">
            <CreateIcon className={classes.icon} />
          </Tooltip>
        );
      } else {
        return included;
      }
    } else {
      return "";
    }
  };

  const handlePlanSelect = (planId) => {
    const plan = plans.find((plan) => plan._id === planId);

    const price = `$${numberWithCommas(plan.price / 100)}`;

    Mixpanel.track("Chose a Plan", {
      name: plan.name,
      id: planId,
      price: price,
    });

    if (showActionButtons) {
      if (planId === "free") {
        props.history.push("/register");
      } else {
        props.history.push("/register", {
          planName: plan.name,
          planId: planId,
          price: price,
        });
      }
    }
  };

  if (loading) {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    );
  } else {
    const gridData = data.gridData;
    const planFeatures = gridData.planFeatures.items;

    return (
      <>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.featureColumn}>Feature</TableCell>
                <TableCell align="center">Free</TableCell>
                {/* <TableCell align="center">Basic</TableCell> */}
                <TableCell align="center">Essential</TableCell>
                <TableCell align="center">Premium</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {console.log(feature)} */}
              {planFeatures.map((feature) => (
                <TableRow key={feature.name}>
                  <TableCell component="th" scope="feature">
                    {feature.name}
                    <CustomTooltip
                      placement="right"
                      arrow
                      // title={feature.info}
                      // open={true}
                      title={documentToReactComponents(feature.info.json)}
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </TableCell>
                  <TableCell align="center">
                    {featureIcon(feature.free)}
                  </TableCell>
                  {/* <TableCell align="center">
                    {featureIcon(feature.basic)}
                  </TableCell> */}
                  <TableCell align="center">
                    {featureIcon(feature.essential)}
                  </TableCell>
                  <TableCell align="center">
                    {featureIcon(feature.premium)}
                  </TableCell>
                </TableRow>
              ))}

              {props.showActionButtons && (
                <TableRow key="buttons">
                  <TableCell component="th" scope="feature"></TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => handlePlanSelect("free")}
                      className={classes.button}
                      color="primary"
                      size="large"
                    >
                      {actionButtonText}
                    </Button>
                  </TableCell>
                  {/* <TableCell align="center">
                    <Button onClick={() => handlePlanSelect('basic')}
                      className={classes.button} color="primary" size='large'
                    >
                      {actionButtonText}
                    </Button>
                  </TableCell> */}
                  <TableCell align="center">
                    <Button
                      onClick={() => handlePlanSelect("essential")}
                      className={classes.button}
                      color="primary"
                      size="large"
                    >
                      {actionButtonText}
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => handlePlanSelect("premium")}
                      className={classes.button}
                      color="primary"
                      size="large"
                    >
                      {actionButtonText}
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {showContact && (
          <div className={classes.help}>
            <div>
              <Typography variant="h6" gutterBottom>
                Not sure what plan is best for you?
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={props.handleOpenContactForm}
              >
                Talk to an Expert
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    plans: state.plans.allPlans,
  };
};

// export default Grid;
export default connect(mapStateToProps, null)(Grid);
