import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { globalStyles } from './constants/globalStyles';

// MUI Imports
import {
  Container, Typography
} from '@material-ui/core';

// Icons
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import TimelineIcon from '@material-ui/icons/Timeline';
// import WhatshotIcon from '@material-ui/icons/Whatshot';
// import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
// import NotInterestedIcon from '@material-ui/icons/NotInterested';

// Extra Icons
import { ReactComponent as FireIcon } from '../../images/icons/local_fire_department_black_24dp.svg';
import { ReactComponent as ReviewIcon } from '../../images/icons/insights_black_24dp.svg';
import { ReactComponent as AssessIcon } from '../../images/icons/ads_click_black_24dp.svg';

const useGlobalStyles = makeStyles({
  ...globalStyles
});

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgb(230, 238, 240)'
  },
  stepsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    '@media (max-width: 780px)' : {
      flexDirection: 'column'
    }
  },
  step: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    borderRadius: '10px',
    textAlign: 'center',
    margin: '10px',
    padding: '30px',
    '& svg': {
      fontSize: '4em',
      marginBottom: '10px'
    }
  },
  stepText: {
    fontSize: '1.9em'
  },
  materialIcon: {
    height: '50px',
    width: '100%',
    // fill: 'rgba(0, 0, 0, 0.5)'
  },
  arrowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: '3em',
      fill: 'rgba(0, 0, 0, 0.3)',
      '@media (max-width: 780px)' : {
        transform: 'rotate(90deg)'
      }
    }
  }
});

const HowItWorks = (props) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const { data } = props
  const steps = data.contentCollection.items

  return(
    <div className={`${classes.root} ${globalClasses.section}`}>
      <Container maxWidth='lg'>
        <Typography variant='h3' align='center' gutterBottom>
          {data.sectionTitle}
        </Typography>
        <div className={classes.stepsContainer}>

          <div className={classes.step}>
            {/* <CreateIcon /> */}
            <AssessIcon className={classes.materialIcon} />
            <Typography className={classes.stepText} variant='h6' gutterBottom align='center'>
              {steps[0].name}
            </Typography>
            <Typography align='center' color="textSecondary">
              {steps[0].description}
            </Typography>
          </div>

          <div className={classes.arrowContainer}>
            <ChevronRightIcon />
          </div>

          <div className={classes.step}>
            {/* <TimelineIcon /> */}
            <ReviewIcon className={classes.materialIcon} />
            <Typography className={classes.stepText} variant='h6' gutterBottom align='center'>
              {steps[1].name}
            </Typography>
            <Typography align='center' color="textSecondary">
              {steps[1].description}
            </Typography>
          </div>

          <div className={classes.arrowContainer}>
            <ChevronRightIcon />
          </div>

          <div className={classes.step}>
            {/* <WhatshotIcon /> */}
            <FireIcon className={classes.materialIcon} />
            {/* <img src={fireIcon} alt="" className={classes.materialIcon} /> */}
            <Typography className={classes.stepText} variant='h6' gutterBottom align='center'>
              {steps[2].name}
            </Typography>
            <Typography align='center' color="textSecondary">
              {steps[2].description}
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HowItWorks;
