import {
  SET_RECORDS_LOADING,
  SET_RECORDS,
  SET_RECORD_OPTIONS,
  ADD_RECORDS_SUCCESS,
  SET_FILTER_OPTIONS_LOADING,
} from "../actions/index";

const initialState = {
  loading: false,
  records: [],
  filters: {},
  successMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_RECORDS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_FILTER_OPTIONS_LOADING:
      return {
        ...state,
        optionsLoading: true,
      };
    case ADD_RECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: action.data.message,
      };
    case SET_RECORDS:
      return {
        ...state,
        loading: false,
        records: action.data.records,
      };

    case SET_RECORD_OPTIONS:
      return {
        ...state,
        loading: false,
        optionsLoading: false,
        filters: action.data.filterOptions,
      };

    default:
      return state;
  }
}
