const shouldQuestionShow = (answers, ownProps) => {
  let show = true;

  // Check dependent question to determine if this question should be shown
  const showIf = ownProps.item.showIf;
  if (showIf) {
    show = false;
    const number = Object.keys(showIf)[0];
    const showIfAnswers = showIf[number];
    const answer = answers?.answers?.[number - 1]?.selection;
    if (answer) {
      showIfAnswers.forEach((showAnswer) => {
        if (showAnswer.toString() === answer) {
          show = true;
        }
      });
    }
  }
  return show;
};

const getAnswer = (answers, ownProps, blankAnswer = "") => {
  let answer;
  if (answers?.answers?.length >= 0) {
    if (answers.answers[ownProps.number - 1]) {
      answer = answers.answers[ownProps.number - 1];
    } else answer = "";
  } else answer = "";
  return answer;
};

module.exports = {
  shouldQuestionShow: shouldQuestionShow,
  getAnswer: getAnswer,
};
