import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { globalStyles } from './constants/globalStyles';
import { Mixpanel } from "../../utils/mixpanel";
import Plans from '../main/Plans';

// MUI Imports
import {
  Container, Typography, Button
} from '@material-ui/core';

const useGlobalStyles = makeStyles({
  ...globalStyles
});

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgb(230, 238, 240)',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
  },
  help: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
});

const OurPlans = (props) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const handlePlanSelect = (planName, planId, price) => {
    Mixpanel.track("Chose a Plan", {
      name: planName,
      id: planId,
      price: price
    });

    props.history.push('/register', {
      planName: planName,
      planId: planId,
      price: price
    });
  }

  return(
    <div className={`${classes.root} ${globalClasses.section}`}>
      <Container maxWidth='lg'>
        <Typography variant='h3' align='center' gutterBottom>
          Our Plans
        </Typography>

        <Plans cta='Get Started' onSelect={handlePlanSelect} />

        <div className={classes.help}>
          <div>
            <Typography variant='h6' gutterBottom>
              Not sure what plan is best for you?
            </Typography>
            {/* <Button variant="contained" component={Link} to="/contact" color="primary" size='large'>
              Talk to an Expert
            </Button> */}
            <Button variant="contained" color="primary" size='large'
              onClick={props.handleOpenContactForm}
            >
              Talk to an Expert
            </Button>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default OurPlans;
