import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Draggable } from "react-beautiful-dnd";

import { colors } from "../../../constants/colors";

// MUI Imports
import {
  Typography, Tooltip, Button, Menu, MenuItem
} from '@material-ui/core';

// Icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

// My Imports
import CustomTooltip from '../../layout/CustomTooltip';

// Styles
const useStyles = makeStyles({
  card: {
    backgroundColor: '#fff',
    padding: '6px 10px',
    marginBottom: '4px',
    borderRadius: '4px',
    userSelect: 'none',
    fontSize: '1.1em',
    position: 'relative',

    /* BOTH of the following are required for text-overflow */
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    boxShadow: '0 1px 0 rgb(9 30 66 / 25%)',
    '&:hover': {
      backgroundColor: '#f6f6f6',
      '& div': {
        display: 'flex',
      }
    }
  },
  cardMenuButton: {
    position: 'absolute',
    height: '100%',
    top: 0,
    right: 0,
    display: 'none',

    // backgroundColor: 'red',
    '& button': {
      margin: '3px',
      padding: '0 3px',
      minWidth: '10px',
      color: 'rgb(135, 135, 135)',
      backgroundColor: '#fff'
    }
  },
  dragging: {
    backgroundColor: '#def0ff'
  }
});

const Card = (props) => {
  const { card, index, showNumbers, moveCardTo } = props
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMoveCard = (cardIndex, sourceBinId, destBinId) => {
    console.log('click!')
    // const source = {
    //   index: cardIndex,
    //   droppableId: sourceBinId
    // }
    // const destination = {
    //   index: 0,
    //   droppableId: destBinId
    // }
    // moveCardTo(source, destination)
    setAnchorEl(null);
  }

  return (
    <Draggable key={card.id} draggableId={card.id} index={index}>
      {(provided, snapshot) => (
        <CustomTooltip placement='right' arrow //interactive={true} // leaveDelay={1500}
          enterDelay={200}
          enterNextDelay={200}
          title={
            <>
              <Typography variant='h5' gutterBottom>
                <b>{card.title}</b>
              </Typography>
              {card.description}
            </>
          }
        >
          <div
            className={`${classes.card} ${snapshot.isDragging && classes.dragging}`}
            ref={provided.innerRef}
            // style={{ 'color': 'red' }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {showNumbers ? `${index+1}. ${card.title}` : `${card.title}`}

            { process.env.NODE_ENV === 'development' &&
              <div className={classes.cardMenuButton}>
                <Button variant='contained' disableElevation size='small'
                  onClick={handleMenuClick}
                >
                  <MoreHorizIcon />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  style={{zIndex: 99999}}
                >
                {
                  // need to connect component to bin ids, iterate over bin ids to generate menu
                }
                  <MenuItem onClick={() => handleMoveCard()}>⭐⭐⭐⭐</MenuItem>
                  <MenuItem onClick={() => handleMoveCard()}>⭐⭐⭐</MenuItem>
                  <MenuItem onClick={() => handleMoveCard()}>⭐⭐</MenuItem>
                  <MenuItem onClick={() => handleMoveCard()}>⭐</MenuItem>
                  <MenuItem onClick={() => handleMoveCard()}>Never Valued</MenuItem>
                </Menu>
              </div>
            }
          </div>
        </CustomTooltip>
      )}
    </Draggable>
  );
}


export default Card;
