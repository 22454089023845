import { gql } from '@apollo/client';

export const GET_DEPRESSION_RESULTS = gql`
  query GetDepressionResults {
  	depressionResults: result(id: "c0O9umaPU75KlMyMSajHN") {
      name
      id
      interpretation { json }
      disclaimer { json }

      # Details Collection
      details: interpretationDetailsCollection {
        items {
          ... on DepressionResults {
            name
            minimal
            moderate
            mild
            high
          }
        }
      }

      # Assessment
      assessment {
        ... on Assessment {
          name
          id
          code
          longName
          shortDescription
          longDescription { json }
          whyIsItUsed
          instructions { json }
          timeToComplete
        }
      }
    }
  }
`
export const GET_NEO_RESULTS = gql`
  query GetNeoResults {
  	neoResults: result(id: "11uO3R4uIdbbvs72kjAZzJ") {
      name
      id
      interpretation { json }
      disclaimer { json }

      # Details Collection
      details: interpretationDetailsCollection {
        neoFacets: items {
          ... on NeoFacet {
            name
            id
            category
            description
            high
            low
            average
          }
        }
      }

      # Assessment
      assessment {
        ... on Assessment {
          name
          id
          code
          longName
          shortDescription
          longDescription { json }
          instructions { json }
          timeToComplete
        }
      }
    }
  }
`

export const GET_MBTI_RESULTS = gql`
query GetMbtiResults($name: String) {
  mbtiResults: result(id: "41Y8TqMSk5wk7uJtseW7yx") {
    name
    id
    interpretation { json }
    disclaimer { json }

    # Slots (E/I)
    slots: extraDetailsCollection {
      items {
        ... on MbtiTypeSlot {
          name
          id
          description
        }
      }
    }

    # Individual Components (I, E, etc.)
    components: interpretationDetailsCollection {
      items {
        ... on MbtiTypeLetters {
          name
          id
          description
        }
      }
    }

    # Assessment
    assessment {
      ... on Assessment {
        name
        id
        code
        longName
        shortDescription
        longDescription { json }
        instructions { json }
        timeToComplete
      }
    }
  }

  # Full Type (INTP)
  mbtiType: mbtiTypeCollection(where: { name: $name }, limit: 1) {
    items {
      name
      id
      functionalTitle
      profile { json }
      motivation {
        ... on Motivation {
          name
          id
          description { json }
        }
      }
    }
  }
}
`

export const GET_CARD_SORT_RESULTS = gql`
  query GetCardSortResults {
  	cardSortResults: result(id: "51eDYFCjqDfKZOGCvcFGQR") {
      name
      id
      interpretation { json }

      # Assessment
      assessment {
        ... on Assessment {
          name
          id
          code
          longName
          shortDescription
          longDescription { json }
          instructions { json }
          timeToComplete
        }
      }
    }
  }
`

export const GET_SII_RESULTS = gql`
  query GetSiiResults {
  	siiResults: result(id: "1Lzf7GlOJ7PE3ufxHFgyY3") {
      name
      id
      interpretation { json }

      # Sections
      sections: interpretationDetailsCollection {
        items {
          ... on SiiSection {
            name
            description
            interpretation
          }
        }
      }

      # Themes (GOT)
      themes: extraDetailsCollection {
        items {
          ... on SiiGot {
            name
            id
            description
            interpretation
          }
        }
      }

      # Assessment
      assessment {
        ... on Assessment {
          name
          id
          code
          longName
          shortDescription
          longDescription { json }
          instructions { json }
          timeToComplete
        }
      }
    }
  }
`
