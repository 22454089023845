import axios from "axios";

export const SET_RECORDS = "UPDATE_RECORDS";
export const SET_RECORD_OPTIONS = "SET_RECORD_OPTIONS";
export const SET_FILTER_OPTIONS_LOADING = "SET_FILTER_OPTIONS_LOADING";
export const SET_RECORDS_LOADING = "SET_RECORDS_LOADING";
export const ADD_RECORDS_SUCCESS = "ADD_RECORD_SUCCESS";

export const setRecordsLoading = () => {
  return {
    type: SET_RECORDS_LOADING,
  };
};

export const setFilterOptionsLoading = () => {
  return {
    type: SET_FILTER_OPTIONS_LOADING,
  };
};

// data via API -> update redux state
export const setRecords = (data) => {
  console.log(data);
  return {
    type: SET_RECORDS,
    data: data,
  };
};

// data via API -> update redux state
export const setRecordOptions = (data) => {
  console.log(data);
  return {
    type: SET_RECORD_OPTIONS,
    data: data,
  };
};

// data via API -> update redux state
export const addRecordSuccess = (data) => {
  console.log(data);
  return {
    type: ADD_RECORDS_SUCCESS,
    data: data,
  };
};

// data via API -> update redux state
export const cleanAddRecordSuccess = (data) => {
  console.log(data);
  return {
    type: ADD_RECORDS_SUCCESS,
    data: data,
  };
};

// Add record to database
export const addRecord = (record) => (dispatch) => {
  dispatch(setRecordsLoading());
  axios.post("/api/records/add-single-record", record).then(
    (response) => {
      dispatch(addRecordSuccess(response.data));
    },
    (error) => {
      console.log(error);
      dispatch(addRecordSuccess(error));
    }
  );
};

// Add record to database
export const cleanAddRecord = (record) => (dispatch) => {
  dispatch(
    cleanAddRecordSuccess({
      loading: false,
      successMessage: "",
    })
  );
};

// Fetch records from backend
export const getRecords = () => (dispatch) => {
  dispatch(setRecordsLoading());
  axios.get("/api/records/get-records").then(
    (response) => {
      dispatch(setRecords(response.data));
    },
    (error) => {
      console.log(error);
    }
  );
};

// Fetch filterd records from backend
export const getFilteredRecords = (filters) => (dispatch) => {
  dispatch(setRecordsLoading());
  const {
    MBTI_1,
    MBTI_2,
    MBTI_3,
    SII_1,
    SII_2,
    SII_3,
    salary,
    min_edu,
    stress_level,
    familyType,
  } = filters;
  axios
    .get(
      `/api/records/get-records?MBTI_1=${encodeURIComponent(
        MBTI_1
      )}&MBTI_2=${encodeURIComponent(MBTI_2)}&MBTI_3=${encodeURIComponent(
        MBTI_3
      )}&SII_1=${encodeURIComponent(SII_1)}&SII_2=${encodeURIComponent(
        SII_2
      )}&SII_3=${encodeURIComponent(SII_3)}&salary=${encodeURIComponent(
        salary
      )}&min_edu=${encodeURIComponent(
        min_edu
      )}&stress_level=${encodeURIComponent(
        stress_level
      )}&familyType=${encodeURIComponent(familyType)}`
    )
    .then(
      (response) => {
        dispatch(setRecords(response.data));
      },
      (error) => {
        console.log(error);
      }
    );
};

// Fetch records options for dropdowns
export const getRecordOptions = (filters) => (dispatch) => {
  dispatch(setFilterOptionsLoading());
  axios.get(`/api/records/get-filter-options`).then(
    (response) => {
      dispatch(setRecordOptions(response.data));
    },
    (error) => {
      console.log(error);
    }
  );
};
