import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// MUI Imports
import {
  Container, Typography, Button, Table, TableBody, TableCell, TableContainer,
  TableRow, TableHead, TableFooter, Paper, CircularProgress
 } from '@material-ui/core';

 // Actions
 import {
   handlePaymentRedirect
 } from '../../actions/index';

const useStyles = makeStyles({
  root: {
    marginTop: '50px'
  },
  tableContainer: {
    margin: '30px 0'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    margin: '0 5px'
  },
  spinner: {
    color: 'rgba(255, 255, 255, 0.5)'
  }
});

const Checkout = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { planName, planId, price } = props.location.state

  const handleCheckout = (planId) => {
    setLoading(true)
    props.handlePaymentRedirect(planId)
  }

  const calculateProcssingFee = () => {
    let fee = JSON.parse(price.split("$")[1])
    let processingFee = JSON.parse((fee * 0.03).toFixed(2))
    return processingFee
  }

  const calculateTotalPrice = () => {
    let fee = JSON.parse(price.split("$")[1])
    let processingFee = calculateProcssingFee()
    return fee + processingFee
  }

  return(
    <Container maxWidth='sm' className={classes.root}>
      <Typography variant='h2'>
        Checkout
      </Typography>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Product
              </TableCell>
              <TableCell align="right">
                Price
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography>
                  {`Wanderlust Careers ${planName} Plan`}
                </Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography>
                  {price}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                <Typography>
                  3% Processing Fee
                </Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography>
                  ${calculateProcssingFee()}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                Total
              </TableCell>
              <TableCell align="right">
                ${calculateTotalPrice()}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <div className={classes.buttonContainer}>
        <Button variant="contained" component={Link} to="/plans" className={classes.button}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" className={classes.button}
          onClick={() => handleCheckout(planId)}
        >
          { loading ?
            <CircularProgress size={25} className={classes.spinner} /> :
            'Purchase'
          }
        </Button>
      </div>

    </Container>
  )
}

const mapDispatchToProps = {
  handlePaymentRedirect
};

const mapStateToProps = state => {
  console.log(state)
  return {
    loading: state.plans.loading,
    plans: state.plans.allPlans
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
