import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EastIcon from "@mui/icons-material/East";

// Styles
const useStyles = makeStyles((theme) => ({
  tableHeading: {
    width: "37%",
    // paddingTop: "12px",
    // paddingBottom: "12px",
    padding: "12px 10px 12px 10px",
    fontSize: "16px",
    fontWeight: "400",
    // color: "white",
    // textAlign: "center",
    // backgroundColor: "#7D80C6",
    // borderBottom: "1px solid #383050",
    [theme.breakpoints.down("lg")]: {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },

  tableRowPurple: {
    color: "white",
    backgroundColor: "#7D80C6",
  },

  tableRowWhite: {
    color: "#383050",
    backgroundColor: "white",
  },

  descTableHeading: {
    width: "100%",
    paddingTop: "22px",
    paddingBottom: "22px",
    fontSize: "16px",
    fontWeight: "400",
    color: "white",
    textAlign: "center",
    backgroundColor: "#7D80C6",
    // borderBottom: "1px solid #383050",
    [theme.breakpoints.down("lg")]: {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },

  tableValue: {
    // width: "100%",
    width: "63%",
    borderLeft: "1px solid #383050",
    fontFamily: "Lato",
    // padding: "20px",
    padding: "12px 10px 12px 10px",
    fontSize: "16px",
    fontWeight: "400",
    [theme.breakpoints.down("lg")]: {
      padding: "16px",
    },
  },
  table_leftContainer_valueBox: {
    // width: "33.33%",
    // width: "100%",
    display: "flex",
    // width: "250px",

    backgroundColor: "#fff",
    // border: "0 0 1px 1px",
    borderBottom: "1px solid #383050",
    // borderRight: "0px",
    overflowX: "hidden",
  },

  table_rightContainer_valueBox: {
    width: "100%",
    // width: "400px",
    backgroundColor: "#fff",
    border: "1px solid #383050",
    borderRadius: "6px",
    overflowX: "hidden",
  },

  lowerContainer: {
    // minWidth: "100%",
    width: "100%",
    overflowX: "auto",
    display: "flex",
    justifyContent: "space-between",
    gap: "5px",
    paddingBottom: "4px",
  },

  leftTableContainer: {
    // display: "flex",
    width: "42.5%",
    overflow: "hidden",
    borderRadius: "6px",
    border: "1px solid #383050",
  },

  rightTableContainer: {
    display: "flex",
    // width: "39%",
    width: "56%",
  },

  descriptionContainer: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 2,
    transition: "all 0.3s ease",
  },
  expandedDescription: {
    WebkitLineClamp: "unset",
  },
}));

const SingleRecord = (props) => {
  const { record } = props;
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const rows = [
    {
      title: "Job Title",
      value: record.jobTitle || "",
      // style: {
      //   borderBottomLeftRadius: "4px",
      //   borderTopLeftRadius: "4px",
      // },
    },
    { title: "Family Type", value: record.familyType },
    { title: "Secondary Family Type", value: record.secondaryFamily },
    { title: "Interest 1", value: record.SII_1 },
    { title: "Interest 2", value: record.SII_2 },
    { title: "Interest 3", value: record.SII_3 },
    { title: "Introvert/Extrovert", value: record.MBTI_1 },
    { title: "Motivation", value: record.MBTI_2 },
    { title: "Judging/Perceiving", value: record.MBTI_3 },
    { title: "Education", value: record.min_edu },
    {
      title: "Stress Level",
      value: record.stress_level === "L" ? "Low" : "High",
    },
    {
      title: "Salary Level",
      value: record.salary <= "1" ? "<$100k" : "$100k+",
      style: {
        borderBottom: 0,
        // borderRight: "1px solid #383050",
        // borderBottomRightRadius: "4px",
        // borderTopRightRadius: "4px",
      },
    },
  ];

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`${classes.lowerContainer} minimal-x-scrollbar`}>
      <div className={classes.leftTableContainer}>
        {rows.map((row, index) => (
          <LeftTableRow
            key={index}
            index={index}
            title={row.title}
            value={row.value}
            style={row?.style}
          />
        ))}
      </div>

      {/* description */}
      <div className={classes.rightTableContainer}>
        <div className={classes.table_rightContainer_valueBox}>
          <div className={classes.descTableHeading}>Description</div>
          <div
            className={classes.tableValue}
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              border: 0,
              width: "100%",
            }}
          >
            <div
              className={`${classes.descriptionContainer} ${
                isExpanded && classes.expandedDescription
              }`}
            >
              {record.description}
            </div>
            <button
              onClick={handleToggle}
              style={{
                border: "0px",
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
            >
              <EastIcon
                fontSize="inherit"
                style={{
                  fontSize: "30px",
                  rotate: isExpanded ? "90deg" : "-40deg",
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const LeftTableRow = ({ index, title, value, style }) => {
  const classes = useStyles();
  return (
    <div className={classes.table_leftContainer_valueBox} style={style}>
      <div
        className={`${classes.tableHeading} ${
          index % 2 ? classes.tableRowPurple : classes.tableRowWhite
        }`}
      >
        {title}
      </div>
      <div className={classes.tableValue}>{value}</div>
    </div>
  );
};

export default SingleRecord;
