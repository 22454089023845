import React, { useState } from "react";
import validator from "validator";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ErrorIcon from "@material-ui/icons/Error";
import { updateAnswer } from "../../../actions";
import { FormHelperText, TextField } from "@material-ui/core";
// My Imports
import { shouldQuestionShow, getAnswer } from "./helpers";
const useStyles = makeStyles({
  textField: {
    display: "block",
  },
  helperText: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

function MultiTextExtension(props) {
  const [errors, setErrors] = useState(false);
  const classes = useStyles();
  const {
    index,
    field,
    multiline,
    rows,
    number,
    textFields,
    item,
    answer,
    userId,
    assessmentId,
  } = props;
  const handleTextChange = (index, event) => {
    const questionNum = event.target.name;
    const value = event.target.value;
    const answer = {
      index: index,
      value: value,
    };
    validate(index, event);
    props.updateAnswer(userId, assessmentId, questionNum, answer, "multiText");
  };
  const validate = (index, event) => {
    const validationErrors = [];
    const characterLimit = textFields[index].characterLimit;
    const text = event.target.value;

    // Required
    if (text.length < 1) {
      validationErrors.push("Field is required.");
    }

    // Character Limit
    if (characterLimit && text.length > characterLimit) {
      validationErrors.push(
        `Text exceeds ${characterLimit} character limit by ${
          text.length - characterLimit
        } characters.`
      );
    }

    // Number
    if (item.validation === "number" && !validator.isInt(text)) {
      validationErrors.push("Not a valid number.");
    }

    if (validationErrors.length > 0) {
      setErrors([...validationErrors]);
    } else {
      setErrors(false);
    }
  };
  const combine = () => {
    let answerText;
    if (answer === []) {
      answerText = "";
    } else if (answer[index]) {
      answerText = answer[index];
    } else {
      answerText = "";
    }
    return (
      <>
        <TextField
          key={index}
          label={field.label}
          fullWidth={false}
          multiline={multiline}
          minRows={rows}
          className={classes.textField}
          value={answerText}
          onChange={(event) => handleTextChange(index, event)}
          name={`${number}`}
          error={errors ? true : false}
          // helperText={
          //   errors && (
          //     <span className={classes.error}>
          //       <ErrorIcon /> {errors[0]}
          //     </span>
          //   )
          // }
        />
        <FormHelperText
          className={classes.helperText}
        >{`${answerText.length}/${textFields[index].characterLimit}`}</FormHelperText>
      </>
    );
  };
  return <>{combine()}</>;
}

const mapStateToProps = (state, ownProps) => {
  const answers = state.answers.byId["interview"];

  return {
    answer: getAnswer(answers, ownProps, []),
    show: shouldQuestionShow(answers, ownProps),
  };
};

const mapDispatchToProps = {
  updateAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiTextExtension);
