import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { colors } from "../../../constants/colors";

// MUI Imports
import { Typography, Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

// My Imports
import CardSort from "./CardSort";

// Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import DoneIcon from "@material-ui/icons/Done";

// Actions
import { submitAnswers } from "../../../actions/index";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: "1 0 auto",
    padding: "20px",
    backgroundColor: "rgb(181 193 202)",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    flex: "1 0 auto",
  },
  footer: {
    padding: "0 5px",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "-12px",
  },
  button: {
    margin: "10px",
  },
  validation: {
    backgroundColor: colors.red,
    color: "#fff",
    fontWeight: 500,
    paddingLeft: "30px",
    padding: "10px",
    borderRadius: "4px",
  },
  header: {
    textAlign: "center",
  },
});

const CareerValues = (props) => {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const validateAnswers = (answers) => {
    let validationErrors = [];
    let answersValid = true;

    // career-values must be empty
    if (answers.start.cards.length > 0) {
      answersValid = false;
      validationErrors.push(
        `Please finish sorting all values (${answers.start.cards.length} left)`
      );
    }

    // Check card count in each column against max/mins
    let { start, ...values } = answers; // remove start column
    Object.keys(values).forEach((column) => {
      const columnData = values[column];
      const cards = columnData.cards;
      const max = columnData.maxCards;
      const min = columnData.minCards;
      if (cards.length > max) {
        answersValid = false;
        validationErrors.push(
          `Too many cards in "${columnData.name}" column (max ${max})`
        );
      } else if (min && cards.length < min) {
        answersValid = false;
        validationErrors.push(
          `Not enough cards in "${columnData.name}" column (min ${min})`
        );
      }
    });
    setErrors([...validationErrors]);
    return answersValid;
  };

  const handleSubmitAnswers = (shouldValidate = true) => {
    let answersValid = true;

    if (shouldValidate) {
      const answers = props.answers.answers;
      answersValid = validateAnswers(answers);
    }

    // if passes validation
    if (answersValid) {
      let answerData = {
        userId: props.user._id,
        assessmentId: "career-values",
        totalQuestions: props.assessment.totalQuestions,
        markComplete: false,
      };
      props.submitAnswers(answerData, false);
      props.history.push("/assess/career-values-rank");
    }
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: "rgb(181 193 202)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    backgroundColor: "white",
    padding: theme.spacing(2),
  }));

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.heading}>
          {props.assessment.name}
        </Typography>
      </div>
      <div className={classes.accordionContainer}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={{ padding: "0px" }}
          >
            <Typography>
              <b>What is it?</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The Career Values Card Sort is one of the most powerful, yet
              under-utilized assessments in vocational psychology--It’s both a
              therapeutic reflection exercise and a career test in one! It’s
              unique in that it’s a qualitative assessment (meaning the data we
              derive from it is thematic, not numerical) and it asks you to
              reflect on your work-life values (something few of us spend much
              time thinking about).
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={{ padding: "0px" }}
          >
            <Typography>
              <b>Why is it used?</b>{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We use this assessment to understand what matters most to you in
              life so we can evaluate how your current path matches up and
              ensure your future career choice reflects these values.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={{ padding: "0px" }}
          >
            <Typography>
              <b>Instructions</b>{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Review the 54 value “cards” on the far left column and drag/drop
              them into each category in terms of your value most. The
              definition for each card will pop up when you hover over it. There
              are limits to how many cards you can place in each category, so
              choose carefully!
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br></br>
        <Typography>
          <b>Time Estimate:</b> 20-30 minutes{" "}
        </Typography>
        <br></br>
      </div>
      {/* BODY */}
      <div className={classes.body}>
        <CardSort bins={props.assessment.questions.valueBins.allIds} />
      </div>

      {/* Footer */}
      <div className={classes.footer}>
        {/* Validation */}
        {errors.length > 0 && (
          <div>
            <ul className={classes.validation}>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        <div className={classes.buttonBox}>
          <Button
            variant="contained"
            component={Link}
            to="/"
            className={classes.button}
            onClick={() => handleSubmitAnswers(false)}
            startIcon={<ArrowBackIcon />}
          >
            Save & Exit
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmitAnswers}
            startIcon={<DoneIcon />}
          >
            I'm Finished
          </Button>

          {process.env.NODE_ENV === "development" && (
            <Button
              variant="contained"
              component={Link}
              to="/assess/career-values-rank"
              className={classes.button}
            >
              Rank (dev)
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  submitAnswers,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    assessment: state.assessments.byId["career-values"],
    answers: state.answers.byId["career-values"],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CareerValues);
