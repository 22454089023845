import { gql } from '@apollo/client';

export const GET_GRID_DATA = gql`
  query getPlanFeatures {
    gridData: section(id: "5bAmRK7a0dwQehZ2bAvrBB") {
      name
      id
      sectionTitle
      planFeatures: contentCollection(limit: 20) {
        items {
          ... on PlanFeature {
            name
            shortDescription
            info { json }
            free
            basic
            essential
            premium
          }
        }
      }
    }
  }
`
