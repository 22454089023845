import { colors } from './colors';

export const theme = {
  palette: {
    // type: 'dark',
    primary: {
      main: colors.blue
    },
    secondary: {
      main: colors.pink
    },
    error: {
      main: colors.red
    },
    warning: {
      main: colors.orange
    },
    info: {
      main: colors.blue
    },
    success: {
      main: colors.green
    },
    test: {
      main: colors.test
    }
  },
  typography: {
    // fontSize: 14, // defalult: 14
    // htmlFontSize: 5, // Tell Material-UI what's the font-size on the html element is. default: 10
    h1: {
      fontWeight: 500,
      fontSize: '5rem'
    },
    h2: {
      fontWeight: 500,
      fontSize: '3rem'
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.85em",
        // color: "red",
        // backgroundColor: "rgba(0, 0, 0, 0.2)"
      }
    }
  }
};
