import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// MUI Imports
import { Container, Typography, Button, Paper } from '@material-ui/core';

// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import DoneIcon from '@material-ui/icons/Done';

// Actions
import { sendFlash, submitAnswers } from '../../actions/index';

const useStyles = makeStyles({
  root: {
    marginTop: '30px',
    padding: '20px'
  },
  paper: {
    padding: '40px'
  },
  buttonBox: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    margin: '10px'
  }
});

const Consent = (props) => {
  const classes = useStyles();

  const handleGiveConsent = () => {
    let answerData = {
      userId: props.user._id,
      assessmentId: 'consent',
      totalQuestions: 1,
      answers: [1]
    }
    props.submitAnswers(answerData)
  }

  return(
    <Container maxWidth='md' className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant='h2'>
          Informed Consent
        </Typography>
        <p>
          Confidentiality, Privileged Information, & Limitations. We take confidentiality very seriously and treat your information as it it were confidential health information: We will not share your information with anyone except for you. Your data will be stored securely in a password-protected cloud. We will keep your results for 3 years, after which it will be destroyed utilizing an appropriate protocol, permanently deleting your data. A complete description of these procedures is available to you upon request—it is the client’s right to view this document prior to signing this consent form. Your answers from this interview will be utilized in the final report of your assessment. If there is anything you want to share, but do not wish to have recorded on your final report, please make a note of this by putting the information in brackets [like so].There is a career theory and/or research-informed rationale behind each question. Your decision to answer all questions is voluntary. If you do not wish to answer a question, feel free to skip. There are character count limitations for some answers—please be clear and concise in your language.
        </p>
        <p>
          I understand that the information obtained in this evaluation is confidential and will not be released to any person or organization without my written permission. The only exceptions to this policy are rare situations in which we are
          required by law to release information with or without client's permission. These limits to confidentiality are usually is not a problem, but if you disclose any of the following types of information in your responses, we may need to share a minimal amount of your information (e.g., your name and address) to keep you and others safe: (a) you disclose plan or intent to kill or cause yourself harm; (b) you disclose a plan or intent to hurt someone else; (c) you reveal or I suspect any ongoing child/elder abuse or maltreatment—We are required by law to report any suspected or actual child abuse or maltreatment to New York Child Protective Services; or (d) You are involved in a court case and I am ordered by a court to disclose information about our work together. Unless ordered by a court to disclose information about you, anything information disclosed between us privileged and unable to be disclosed in a court of law. We will do our best to protect your confidentiality within the law. If we are required to disclose information to a court, you will be informed.
        </p>
        <p>
          Please note: We will always do our best to disclose the least amount of information possible if any of the above events occur. In some cases I will share confidential information with professionals to assist with your case (e.g., supervisors, colleagues) and some subordinates (e.g., administrators) will be given access to basic information about you (e.g., name, e-mail address).
        </p>
        <p>
          Diagnosis. In order for insurance to be used, an accurate diagnosis must be assigned to the client in order to obtain reimbursement. In the DSM-V, there are no career-related diagnosis, thus, Career Assessment is not covered by insurance. In the future, if you decide to use a healthcare plan to subsidize the cost of testing or future counseling, you should be aware of the following: (1) Certain diagnoses can adversely affect obtaining some employment positions (e.g., government positions), can be used negatively in some child custody proceedings, and can affect future health care insurability; and (2) knowledge of the diagnosis is the right of the client.
        </p>
        <p>
          Testing. Through the use of a variety of standard psychological tests, we will attempt to answer the questions that have brought you for this assessment. These questions generally concern career decision making, career functioning, personality, and/or coping styles. Throughout the assessment process you have the right to inquire about the nature or purpose of all procedures. You also have the right to your test results, interpretations, and recommendations. The assessment process generally involves an informational interview followed by the administration of psychological tests. Although it is sometimes possible to complete the testing procedure in one sitting, it is common for people to break up the assessment battery over multiple days. Once testing is completed, the data will be analyzed and a report will be written. You will then have the opportunity to meet with your coach to discuss the results and receive a copy of the report.
        </p>
        <p>
          Fees. The fee for a Career Assessment is based on the number and type of tests included in the assessment battery as well as the level of complexity to integrate the information into the results. We do not bill insurance companies. The full fee must be paid before the initial psychosocial interview.
        </p>
        <p>
          I have read this statement, had sufficient time to be sure that I considered it carefully, asked any questions via e-mail to (hello@wanderlustcareers.com) and understand and the above statements. I understand the limits to confidentiality required by law. I understand my rights and responsibilities as a client and my assessor's responsibilities to me. I agree to undertake career assessment with Wanderlust Careers. I know I can end the career assessment at any time I wish. I understand that I can refuse any requests or suggestions made by Wanderlust Careers. I am over the age of eighteen.
        </p>
        <div className={classes.buttonBox}>
          <Button
            variant="contained" component={Link} to="/" className={classes.button}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>

          <Button
            variant="contained" color="primary" className={classes.button}
            component={Link} to="/"
            onClick={handleGiveConsent}
            startIcon={<DoneIcon />}
          >
            I Agree
          </Button>
        </div>
      </Paper>
    </Container>
  )
}

const mapDispatchToProps = {
  submitAnswers,
  sendFlash
};


const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Consent);
