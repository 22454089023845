export const gotBoundaries = {
  Realistic: {
    "Very Little": {
      m: 43,
      f: 34
    },
    "Little": {
      m: 50,
      f: 36
    },
    "Average": {
      m: 61,
      f: 51
    },
    "High": {
      m: 66,
      f: 56
    },
    "Very High": {
      m: 87,
      f: 87
    }
  },
  Investigative: {
    "Very Little": {
      m: 38,
      f: 35
    },
    "Little": {
      m: 45,
      f: 41
    },
    "Average": {
      m: 58,
      f: 56
    },
    "High": {
      m: 64,
      f: 62
    },
    "Very High": {
      m: 78,
      f: 78
    }
  },
  Artistic: {
    "Very Little": {
      m: 36,
      f: 37
    },
    "Little": {
      m: 42,
      f: 44
    },
    "Average": {
      m: 56,
      f: 59
    },
    "High": {
      m: 62,
      f: 64
    },
    "Very High": {
      m: 76,
      f: 76
    }
  },
  Social: {
    "Very Little": {
      m: 35,
      f: 39
    },
    "Little": {
      m: 41,
      f: 46
    },
    "Average": {
      m: 55,
      f: 59
    },
    "High": {
      m: 60,
      f: 65
    },
    "Very High": {
      m: 83,
      f: 83
    }
  },
  Enterprising: {
    "Very Little": {
      m: 37,
      f: 37
    },
    "Little": {
      m: 43,
      f: 42
    },
    "Average": {
      m: 58,
      f: 56
    },
    "High": {
      m: 64,
      f: 62
    },
    "Very High": {
      m: 80,
      f: 80
    }
  },
  Conventional: {
    "Very Little": {
      m: 38,
      f: 35
    },
    "Little": {
      m: 44,
      f: 42
    },
    "Average": {
      m: 57,
      f: 57
    },
    "High": {
      m: 63,
      f: 64
    },
    "Very High": {
      m: 90,
      f: 90
    }
  }
};
