import axios from 'axios'

export const SET_USER_DATA = 'SET_USER_DATA'
export const EXPUNGE_USER_DATA = 'EXPUNGE_USER_DATA'
export const SET_USER_LOADING = 'SET_USER_LOADING'
export const SET_USER_CONSENT = 'SET_USER_CONSENT'

export const LOAD_ALL_USER_DATA = 'LOAD_ALL_USER_DATA'
export const SET_ALL_USERS_LOADING = 'SET_ALL_USERS_LOADING'
export const SET_USER_RESULTS = 'SET_USER_RESULTS'
export const SET_USER_SCHEDULE = 'SET_USER_SCHEDULE'
export const UPDATE_INTERVIEW_QUESTIONS = 'UPDATE_INTERVIEW_QUESTIONS'

// Fetch user data from backend
// userId param is only used for admin logging in as a user (for testing)
export const fetchUserData = (userId) => (dispatch) => {
	dispatch(setUserLoading())
	if (!userId) {
		axios.get('/api/users/current').then(
			(response) => {
				dispatch(setUserData(response.data))
			},
			(error) => {
				console.log(error)
			}
		)
	} else {
		// special case of /api/users/current endpoint where we make the current user someone else
		axios.get(`/api/users/current/${userId}`).then(
			(response) => {
				dispatch(setUserData(response.data)) // log in as new user
			},
			(error) => {
				console.log(error)
			}
		)
	}
}

// Fetch demo user data from backend
export const fetchDemoUserData = () => (dispatch) => {
	// dispatch(setUserLoading())
	axios.get('/api/users/results/demo').then(
		(response) => {
			dispatch(setUserData(response.data))
		},
		(error) => {
			console.log(error)
		}
	)
}

// API call to set add user appointment
export const requestSetUserSchedule = (data) => (dispatch) => {
	axios.post('/api/users/schedule', data).then(
		(response) => {
			const { success } = response.data
			if (success) {
				dispatch(setUserSchedule(response.data))
			}
		},
		(error) => {
			console.log(error)
		}
	)
}

// sets redux state with meeting scheduled
export const setUserSchedule = (data) => {
	return {
		type: SET_USER_SCHEDULE,
		meetingType: data.type,
		eventId: data.eventId,
		userId: data.userId,
		startTime: data.startTime,
	}
}

// used for admin to view user results
export const fetchUserResults = (userId) => (dispatch) => {
	axios.get(`/api/users/${userId}`).then(
		(response) => {
			dispatch(setUserResults(userId, response.data))
			console.log('USER RESULTS')
			console.log(response.data)
		},
		(error) => {
			console.log(error)
		}
	)
}

export const fetchUserQuestions = (assessmentId, version) => (dispatch) => {
	// dispatch(setUserLoading())
	axios.get(`/api/users/questions/${assessmentId}/${version}`).then(
		(response) => {
			dispatch(updateInterviewQuestions(response.data))
		},
		(error) => {
			console.log(error)
		}
	)
}

export const updateInterviewQuestions = (questionData) => {
	return {
		type: UPDATE_INTERVIEW_QUESTIONS,
		questionData: questionData,
	}
}

export const setUserResults = (userId, data) => {
	return {
		type: SET_USER_RESULTS,
		userId: userId,
		data: data,
	}
}

// initiate redux state with all user data from db
// answer data is excluded (i think)
export const setUserData = (data) => {
	return {
		type: SET_USER_DATA,
		data: data,
	}
}

// Remove all user data on logout
export const expungeUserData = () => {
	return {
		type: EXPUNGE_USER_DATA,
	}
}

// Change user data loading status to true
export const setUserLoading = () => {
	return {
		type: SET_USER_LOADING,
	}
}

// set user consent to true in redux
export const setUserConsent = () => {
	return {
		type: SET_USER_CONSENT,
	}
}

//////////////////
// ALL USERS  ////
/////////////////

// Fetch all user data
export const getAllUsers = () => (dispatch) => {
	dispatch(setAllUsersLoading())
	axios.get('/api/users/').then(
		(response) => {
			dispatch(loadAllUserData(response.data))
		},
		(error) => {
			console.log(error)
		}
	)
}

export const getFilteredUsers = (params) => (dispatch) => {
	dispatch(setAllUsersLoading())
	axios.get(`/api/users/search/${params}`).then(
		(response) => {
			dispatch(loadAllUserData(response.data))
		},
		(error) => {
			console.log(error)
		}
	)
}

export const setAllUsersLoading = () => {
	return {
		type: SET_ALL_USERS_LOADING,
	}
}

export const loadAllUserData = (data) => {
	return {
		type: LOAD_ALL_USER_DATA,
		data: data,
	}
}
