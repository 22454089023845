import React from "react";
import "./styles.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
// MUI Imports
import { makeStyles } from "@material-ui/core/styles";

import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

// Styles
const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },

  heading: {
    fontSize: "46px",
    fontWeight: "700",
    color: "#7D80C6",
  },
  labelText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "black",
    fontFamily: "Roboto",
    paddingTop: "34px",
    paddingBottom: "4px",
    textAlign: "center",
    width: "80%",
  },

  iconContainer: {
    backgroundColor: "#7D80C6",
    width: "400px",
    textAlign: "center",
  },

  icon: {
    color: "white",
    width: "100px !important",
    height: "100px !important",
    marginTop: "70px",
    marginBottom: "70px",
  },
  doneBtn: {
    paddingLeft: "60px",
    paddingRight: "60px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontWeight: " 400",
    fontSize: "16px",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    backgroundColor: "#7D80C6",
    color: "white",
    borderRadius: "4px",
    border: "none",
    marginBottom: "28px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#6B6EB5",
    },
  },
  dialogPaper: {
    borderRadius: "10px",
  },
}));

export default function SuccessModal({ handleSuccessClose, openSuccessModal }) {
  const classes = useStyles();

  return (
    <Dialog
      open={openSuccessModal}
      // onClose={handleSuccessClose}
      classes={{ paper: classes.dialogPaper }}
    >
      {/* <DialogTitle disableTypography className={classes.mobileWarningTitle}> */}
      <div className={classes.iconContainer}>
        <CheckCircleIcon className={classes.icon} />
      </div>
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography className={classes.labelText}>
          The record has been submitted successfully.
        </Typography>
        <br />
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Link to="/admin/search-record">
          <Button onClick={handleSuccessClose} className={classes.doneBtn}>
            Done
          </Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
}
