import axios from "axios";

export const UPDATE_FLASH = "UPDATE_FLASH";
export const CLEAR_FLASH = "CLEAR_FLASH";

// update redux store with new message
export const sendFlash = (message, type) => {
  return {
    type: UPDATE_FLASH,
    message: message,
    messageType: type
  };
};

// clear old message
export const clearFlash = () => {
  return {
    type: CLEAR_FLASH
  };
};

export const dispatchContactEmail = formData => async dispatch => {
  console.log('Sending Email...', formData)

  const response = await axios.post("/api/contact", formData)
    .catch(err => console.log(err))
  if (response) {
    console.log('response:', response.data)
    dispatch(sendFlash('Contact Form Successfully Submitted', 'success'));
  }
};
