import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// My Components
import FullPageBkg from "../layout/FullPageBkg";

// MUI Imports
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography } from '@material-ui/core';

import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

// Actions
import { requestPasswordReset } from "../../actions/index";

const useStyles = makeStyles({
  root: {
    minWidth: '300px'
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px'
  }
});

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");

  const { isAuthenticated } = props.auth
  const errors = props.errors

  // If logged in and user navigates to Login page, should redirect them to dashboard
  useEffect(() => {
    if (isAuthenticated) props.history.push("/");
  }, [isAuthenticated]);

  const handleEmailChange = e => {
    setEmail(e.target.value)
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.requestPasswordReset(email);
  };

  const classes = useStyles();

  return(
    <FullPageBkg>
      <div className={classes.root}>
        <Typography variant="h2" gutterBottom>
          Forgot Your <br/> Password?
        </Typography>
        <Typography>
          Don't have an account? <Link to="/register">Sign Up</Link>
        </Typography>

        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TextField id="email" label="Email" fullWidth
            onChange={handleEmailChange}
            value={email}
            error={errors.email || errors.emailnotfound ? true : false}
            helperText={errors.email || errors.emailnotfound}
            type="email"
          />
          <div className={classes.buttonBox}>
            <Button component={Link} to="/" startIcon={<ArrowBackIos />}>
              Back
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Reset Password
            </Button>
          </div>
        </form>
      </div>
    </FullPageBkg>
  )
}

const mapDispatchToProps = {
  requestPasswordReset
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
