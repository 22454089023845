const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const round = (value, decimals = 2) => {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

const debounce = (func, wait = 20, immediate = true) => {
  let timeout;
  return function() {
    const context = this;
    const args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

module.exports = {
  capitalize: capitalize,
  round: round,
  debounce: debounce
}
