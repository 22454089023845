const themes = {
  investigative: {
    main: 'rgb(222, 244, 254)',
    dark: 'rgb(204, 226, 236)',
    text: '#000'
  },
  realistic: {
    main: 'rgb(252, 234, 202)',
    dark: 'rgb(233, 216, 185)',
    text: '#000'
  },
  artistic: {
    main: 'rgb(249, 250, 214)',
    dark: 'rgb(230, 231, 195)',
    text: '#000'
  },
  conventional: {
    main: 'rgb(240, 219, 249)',
    dark: 'rgb(225, 204, 235)',
    text: '#000'
  },
  enterprising: {
    main: 'rgb(252, 220, 217)',
    dark: 'rgb(233, 202, 199)',
    text: '#000'
  },
  social: {
    main: 'rgb(215, 249, 210)',
    dark: 'rgb(200, 233, 195)',
    text: '#000'
  }
}

const makeStyle = (main, darken, text) => {
  return {
    color: text,
    backgroundColor: main,
    "&:hover": {
      backgroundColor: darken,

      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: main
      }
    },
    "&:focus": {
      backgroundColor: main
    }
  }
}

let colorStyles = {}

// Populate colorStyles object
Object.keys(themes).forEach(themeName => {
  const theme = themes[themeName]
  colorStyles[themeName] = makeStyle(theme.main, theme.dark, theme.text)
})

export { colorStyles }
