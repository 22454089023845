import React from 'react';
import classes from '../../styles/admin.module.css';

const UserPlan = ({ label, id, selected, onSelect }) => {
	const [planSelected, setPlanSelected] = React.useState(false);

	const clickHandler = (e) => {
		const id = e.target.id;
		if (planSelected) {
			setPlanSelected(false);
			onSelect(id, false);
		} else {
			setPlanSelected(true);
			onSelect(id, true);
		}
	};
	return (
		<div
			className={classes.userplan}
			id={id}
			onClick={clickHandler}
			style={{ backgroundColor: `${planSelected ? 'gray' : 'white'}` }}
			selected={planSelected}
		>
			{label}
		</div>
	);
};

export default UserPlan;
