import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion"

// MUI Imports
import {} from '@material-ui/core';

const useStyles = makeStyles({
  root: {},
  bkg: {
    fill: 'rgba(0, 0, 0, 0.01)'
  },
  main: {
    fill: 'rgba(0, 0, 0, 0.05)'
  },
  dividerLine: {
    strokeWidth: '0.2',
    stroke: 'rgb(128, 128, 128, 0.8)',
    strokeDasharray: '0.5'
  },
  indicator: {
    fill: 'rgba(124, 124, 124, 0.5)'
  },
  text: {
    fontSize: '0.12em',
    letterSpacing: '0.04em'
  },
  clear: {
    fill: 'rgba(114, 255, 111, 0.1)'
  }
});

// For use with PSS
const PssVis = (props) => {
  const classes = useStyles();

  const lines = [ 25, 35, 45, 55, 65, 75 ]
  const height = 7
  const labelHeight = 0

  return(
    <svg viewBox={`20 0 60 ${height}`} xmlns="http://www.w3.org/2000/svg" className={classes.root}>

      {/* Main Rect */}
      <rect width="100" y={labelHeight} height={height-labelHeight} className={classes.main} />
      <rect width="35" height={height} className={classes.clear} />
      <rect width="35" x='65' height={height} className={classes.clear} />

      {lines.map((dist, index) => (
        <g key={index}>
          {/* Divider Line */}
          <line x1={dist} y1={2.5} x2={dist} y2={height} className={classes.dividerLine} />

          {/* Text Label */}
          <text x={dist} y={2} textAnchor="middle" className={classes.text}>{dist}</text>
        </g>
      ))}

      <text x='30' y={height - 2} textAnchor="middle" className={classes.text}>Clear</text>
      <text x='50' y={height - 2} textAnchor="middle" className={classes.text}>Midrange</text>
      <text x='70' y={height - 2} textAnchor="middle" className={classes.text}>Clear</text>

    </svg>
  )
}

export default PssVis;
