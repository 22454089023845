import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion"

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    height: '20px',
    width: '100%',
    marginBottom: '5px',
    position: 'relative',
    overflow: 'hidden'
  },
  bkg: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: '-5%'
  },
  bar: {
    backgroundColor: 'rgba(77, 110, 227, 1)',
    height: '100%',
  },
  dividerLine: {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    top: 0,
    left: '20%',
    height: '100%',
    width: '1px'
  }
});

// For indicating 1-100 values
// can cut off w/ min & max props
const Bar = (props) => {
  const { value, min, max, height } = props

  const lines = [ 5, 10, 15, 20, 30, 40, 50, 55, 60, 65 ]

  // default props?

  const classes = useStyles();

  return(
    <div className={classes.root}>
      <div className={classes.bkg}>
        <motion.div className={classes.bar}
          animate={{width: `${value}%`}}
          initial={{width: 0}}
        />
        {/* <div className={classes.dividerLine} /> */}
        {lines.map((dist, index) => (
          <line key={index} style={{ left: `${dist}%`}} className={classes.dividerLine} />
        ))}
      </div>


      {/* BKG Rect */}
      {/* <rect width="100" height={height} className={classes.bkg} /> */}

      {/* Value Rect */}
      {/* <motion.div className={classes.bar}
        // animate={{width: value}}
        // initial={{width: 0}}
      /> */}



    </div>
  )
}

export default Bar;
