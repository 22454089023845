import {
  SET_PLANS_LOADING, SET_PLAN_DATA
} from "../actions/index";

const initialState = {
  loading: false,
  allPlans: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PLANS_LOADING:
      return {
        ...state,
        loading: true
      };

    case SET_PLAN_DATA:
      return {
        loading: false,
        allPlans: action.data
      };

    default:
      return state;
  }
}
